import React, { useEffect, useState } from "react";
import Header from "./Header";
import { APIEndpoints } from "./APIEndpoints";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function ProcessedSessions() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const totalAmount = location?.state?.totalAmount;
  const totalSessions = location?.state?.totalSessions;
  const processDate = location?.state?.processDate;
  const formattedPayDate = location?.state?.formattedPayDate;

  const [data, setData] = useState({});

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null){
          handle401Response();
      }
      const { access_token, sf_id } = token;
      const response = await axios.get(
        `${APIEndpoints.PaidSessions}${sf_id}/${processDate}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        }
      );
      if (response.status === 200) {
        setData(response?.data?.grouped_sessions);
        console.log(response?.data?.grouped_sessions);
        setIsLoading(false);
      } else {
        toast.success("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
      if(error.response.status === 401){
        handle401Response();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const convertMinutesToHours = (minutes) => {
  //   const hours = Math.floor(minutes / 60);
  //   const remainingMinutes = minutes % 60;
  //   return `${hours}h ${remainingMinutes}m`;
  // };

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };


  return (
    <>
      <ToastContainer />
      <Header />
      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid custom-container my-5">
          <div className="container-fluid my-5 h-100">
            {data && data.length > 0 ? (
              <div>
                <h5 className="mb-3 font-weight-bold px-2">
                  ${totalAmount} paid for {totalSessions}{" "}
                  {totalSessions === 1 ? "session" : "sessions"}
                </h5>
                <p className="mb-3 font-weight-bold px-2">
                  Process Date: {formattedPayDate}
                </p>
              </div>
            ):(
              <div className="text-center">
                <h5 className="font-weight-bold">No sessions found.</h5>
              </div>
            )}

            {data &&
              data.length > 0 &&
              data.map((item, index) => (
                <div className="box d-flex flex-column p-3 mb-3 rounded">
                  <div
                    className="d-flex justify-content-between"
                    aria-expanded={openIndex === index}
                    aria-controls={`collapse${index}`}
                    onClick={() => toggleAccordion(index)}
                  >
                    <div>
                      <h5>
                        <strong>
                          {item?.child_name}: ${item?.total_amount}
                        </strong>
                      </h5>
                      <p>
                        {item?.total_service_completed}{" "}
                        {item?.total_service_completed === 1
                          ? "Session"
                          : "Sessions"}
                        , {item?.total_session_time}{" "}
                        {item?.total_session_time === 1 ? "Hour" : "Hours"}
                      </p>
                      <p>Avg. Hourly Rate: ${item?.average_pay_rate}</p>
                    </div>
                    <div>
                      <i
                        className={`fa ${
                          openIndex === index
                            ? "fa-minus-circle"
                            : "fa-plus-circle"
                        }`}
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => toggleAccordion(index)}
                      ></i>
                    </div>
                  </div>
                  <div
                    id={`collapse${index}`}
                    className={`accordion-collapse collapse ${
                      openIndex === index ? "show" : ""
                    } table-responsive`}
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Service</th>
                          <th>Hours</th>
                          <th>Rate</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.sessions.map((s, sessionIndex) => (
                          <tr key={sessionIndex}>
                            <td>{s?.service_completed}</td>
                            <td>{s?.service_name}</td>
                            <td>{s?.session_time}</td>
                            <td>${s?.pay_rate}</td>
                            <td>${s?.total_amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}

            {/* <div className="box d-flex flex-column p-3 mb-3 rounded">
              <div className="d-flex justify-content-between">
                <div>
                  <h5>
                    <strong>Child Name: $455</strong>
                  </h5>
                  <p>7 sessions, 7 Hours</p>
                  <p>Avg. Hourly Rate: $65</p>
                </div>
                <div>
                  <i
                    className="fa fa-plus-circle"
                    style={{ color: "red" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  ></i>
                </div>
              </div>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse table-responsive"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Service</th>
                      <th>Hours</th>
                      <th>Rate</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="box d-flex flex-column p-3 mb-3 rounded">
              <div className="d-flex justify-content-between">
                <div>
                  <h5>
                    <strong>Child Name: $455</strong>
                  </h5>
                  <p>7 sessions, 7 Hours</p>
                  <p>Avg. Hourly Rate: $65</p>
                </div>
                <div>
                  <i
                    className="fa fa-plus-circle"
                    style={{ color: "red" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  ></i>
                </div>
              </div>
              <div
                id="collapseThree"
                class="accordion-collapse collapse table-responsive"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Service</th>
                      <th>Hours</th>
                      <th>Rate</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default ProcessedSessions;
