import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import { APIEndpoints } from "./APIEndpoints";
import Pagination from "@mui/material/Pagination";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function SessionHistory() {
  const location = useLocation();
  const navigate = useNavigate();
  const [descriptionOptions, setDescriptionOptions] = useState(
    location?.state?.descriptionOptions
  );

  useEffect(() => {
    if (!descriptionOptions) {
      navigate("/");
      return
    }
  }, [descriptionOptions, navigate]);

  const [isloading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const [totalData, setTotalData] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const fetchDescriptionOptions = async () => {
    try {
      setIsLoading(true);
      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null){
          handle401Response();
      }
      const { access_token } = token;

      const response = await fetch(
        `${APIEndpoints.SESSION_HISTORY}${descriptionOptions.therapist_service_id}/${pageNum}/${rowsPerPage}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`,
          },
        }
      );

      if (response.status === 401) {
        // Handle 401 Unauthorized
        handle401Response();
        return;
      }

      const responseData = await response.json();
      setData(responseData.sessions);
      setTotalData(responseData.total_size);
      setPageNum(pageNum+1);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (descriptionOptions) {
      fetchDescriptionOptions();
    }
  }, [descriptionOptions]);

  return (
    <>
      <Header />
      {isloading ? (
        <div className="detail-page container-fluid">
          <div className="container my-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container custom-container my-5">
            <div className="p-3">
              <h5>{descriptionOptions?.child_name}</h5>
              <p className="mb-0">
                Service Type: {descriptionOptions?.service_name}
              </p>
              <p className="mb-0">
                Service start Date: {descriptionOptions?.service_start_date}
              </p>
              <p className="mb-0">
                Interval: {descriptionOptions?.service_time}
              </p>
            </div>
            <div className="p-3">
              <div className="row justify-content-between my-3">
                <div className="col">
                  <h5>Session Date</h5>
                </div>
                <div className="col">
                  <h5>Location</h5>
                </div>
                <div className="col">
                  <h5>Type Code</h5>
                </div>
                <div className="col text-end">
                  <h5 style={{ fontSize: "1rem" }}>
                    On EIMS/ Parent Signature
                  </h5>
                </div>
              </div>

              {data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <div key={index} className="row justify-content-between my-3">
                    <div className="col">
                      <p>
                        {item.session_date} {item.start_time} - {item.end_time}
                      </p>
                    </div>
                    <div className="col">
                      <p>{item.service_location}</p>
                    </div>
                    <div className="col">
                      <p>{item.service_type_code}</p>
                    </div>
                    <div className="col text-end">
                      {item.parent_signature ? (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={`flexCheckChecked${index}`}
                          checked
                        />
                      ) : (
                        <i className="fa-regular fa-circle-xmark"></i>
                      )}
                    </div>
                  </div>
                ))}

              {data && data.length > 19 && (
                <div className="flex justify-end my-3 w-[80%] m-auto py-3">
                  <Pagination
                    count={Math.ceil(totalData / rowsPerPage)}
                    page={pageNum-1}
                    onChange={fetchDescriptionOptions}
                  />
                </div>
              )}
              
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SessionHistory;
