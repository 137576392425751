import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { APIEndpoints } from "./APIEndpoints";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function UploadDocument() {
  const [isLoading, setIsLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null){
          handle401Response();
      }
      const { access_token } = token;

      const response = await axios.get(
        `${APIEndpoints.APP_DOCUMENT_REFERENCE}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        }
      );
      if (response.status === 200) {
        setData(
          response.data.data.map((item) => ({
            ...item,
            downloading: false, // Add downloading property to each item
          }))
        );
      } else {
        console.log("kk");
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setIsLoading(false);
    } catch (error) {
      if(error.response.status === 401){
        // handleClearCookie();
        handle401Response();
      }
      // Handle error
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function downloadDocument(id, name, index) {
    try {
      const newData = [...data];
      newData[index].downloading = true; // Set downloading status for this item
      setData(newData);

      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null){
          handle401Response();
      }
      const { access_token } = token;

      const response = await axios.get(
        `${APIEndpoints.DOWNLOAD_FORM25_PDF}${id}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        }
      );
      if (response.data.file !== "") {
        downloadBase64File(response.data.file, name);
      } else {
        console.error("Invalid file data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if(error.response.status === 401){
        // handleClearCookie();
        handle401Response();
      }
    } finally {
      // Reset downloading status after download, whether successful or not
      const newData = [...data];
      newData[index].downloading = false;
      setData(newData);
    }
  }

  function downloadBase64File(base64Data, fileName) {
    try {
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
    } catch (error) {
      console.error("Error creating object URL:", error);
    }
  }

  return (
    <>
      <Header />
      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <ToastContainer style={{ top: "7rem", position: "fixed" }} />
          <div className="container custom-container my-5">
            <div className="row my-4">
              <Link
                to="/upload-onboarding/doc"
                download
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="box d-flex justify-content-between p-3 mb-3 rounded">
                  <div>
                    <p className="ms-2">Upload Onboarding Document</p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </Link>
              .
              <div className="hr-container my-3">
                <hr />
                <div className="hr-text">
                  <h5>Download Document Templates</h5>
                </div>
              </div>
              {data.map((item, index) => (
                <div
                  className="box d-flex justify-content-between p-3 mb-3 rounded"
                  key={index}
                >
                  <div>
                    {item.type === "Link" ? (
                      <a
                        href={item.url}
                        style={{ textDecoration: "none", color: "blue" }}
                        target="_blank"
                      >
                        <p className="ms-2">{item.name}</p>
                      </a>
                    ) : (
                      <p className="ms-2">{item.name}</p>
                    )}
                  </div>
                  <div className="my-auto">
                    {item.type === "Link" ? (
                      <a href={item.url} target="_blank">
                        <p></p>
                      </a>
                    ) : item.downloading ? (
                      <div className="spinner-border text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <i
                        className="fa fa-download"
                        aria-hidden="true"
                        onClick={(e) => {
                          e.preventDefault();
                          downloadDocument(
                            item.content_document_id,
                            item.name,
                            index
                          );
                        }}
                      ></i>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default UploadDocument;
