import React, { useEffect, useState } from "react";
import Header from "./Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { APIEndpoints } from "./APIEndpoints";
import axios from "axios";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function PaymentScriptRun() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const verified = location?.state?.verified || false;
  const [amount, setAmount] = useState(0);
  const [selectedMonths, setSelectedMonths] = useState([""]);

  useEffect(() => {
    if (!verified) {
      navigate("/authenticate/payment-password");
    }
  }, [navigate, verified]);

  const handleSubmit = async () => {
    if (amount <= 0) {
      toast.error("Please enter a valid amount.");
      return;
    }
    if (!selectedMonths) {
      toast.error("Please select a month/year to exclude.");
      return;
    }

    setIsLoading(true);

    const url = APIEndpoints.RunPaymentFileScript;
    const requestBody = {
      max_amount: amount,
      exclude_date: selectedMonths,
    };

    try {
      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null){
          handle401Response();
      }
      const { access_token } = token;
      const response = await axios.post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`,
        },
      });

      setIsLoading(false);

      if (response.status === 200 || response.status === 201) {
        // Loop through the object to download each file
        console.log(response?.data?.files);
        const fileDataArray = response?.data?.files;
        fileDataArray.forEach((fileDataObj) => {
          // Each `fileDataObj` is an object containing a single key-value pair
          Object.keys(fileDataObj).forEach((fileName) => {
            const base64Data = fileDataObj[fileName];
            downloadCSVFile(base64Data, fileName);
          });
        });
        setIsLoading(false);
        setSelectedMonths([""]);
        setAmount(0);
        toast.success("Payment file generated successfully!");
        setTimeout(() => {
          navigate("/");
        }, 5000);
      }
    } catch (error) {
      if(error.response.status === 401){
        handle401Response();
      }
      setIsLoading(false);
      if (error.response) {
        // The request was made and the server responded with a status code outside of the range of 2xx
        const { status, data } = error.response;
        console.log(status, data);

        if (status === 400 && data.message) {
          toast.error(data.message); // Display the specific backend error message
        } else {
          toast.error("Something went wrong.");
        }
      } else {
        // Something else went wrong during the request, such as no response from server
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const handleAddMonth = () => {
    setSelectedMonths([...selectedMonths, ""]);
  };

  const handleMonthChange = (index, value) => {
    const updatedMonths = [...selectedMonths];
    updatedMonths[index] = value;
    setSelectedMonths(updatedMonths);
  };

  const handleRemoveMonth = (index) => {
    const updatedMonths = selectedMonths.filter((_, i) => i !== index);
    setSelectedMonths(updatedMonths);
  };

  function downloadCSVFile(base64Data, fileName) {
    // Decode base64 string to binary data
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "text/csv" });

    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Programmatically click the download link
    link.click();

    // Clean up the URL object after download
    URL.revokeObjectURL(link.href);
  }

  // // Example usage with the array you provided:
  // const fileData = {
  //   "20240913-01.csv":
  //     "MSwxLDI0MDkxMywwODMxLDIsMDAwMDAwNTYxMzc1LDAwMDAwMDAwMDAwMCwyCjUsMjIwLDEyMzQ1Njc4OTAxMjM0NTY3LFBQRCxQQVlST0xMLDI0MDkxNiwwMDAwMDA0MjQxMjUsMDAwMDAwMDAwMDAwLDI0MDYwMSwwMDAxCjYsMjIsNDY0Mzg3NDM3MzgsMjc4MjkxMzc5MTgzLDAwMDA0MjQxMjUsYTA0RDYwMDAwMDU4OFJ1LEpPSE4gRE9FLDI0MDYwMTAwMDEsCjUsMjIwLDEyMzQ1Njc4OTAxMjM0NTY3LFBQRCxQQVlST0xMLDI0MDkxNiwwMDAwMDAxMzcyNTAsMDAwMDAwMDAwMDAwLDI0MDcwMSwwMDAxCjYsMjIsNDY0Mzg3NDM3MzgsMjc4MjkxMzc5MTgzLDAwMDAxMzcyNTAsYTA0RDYwMDAwMDU4OFJ1LEpPSE4gRE9FLDI0MDcwMTAwMDEsCg==",
  //   "20240913-02.csv":
  //     "MSwxLDI0MDkxMywwODMxLDIsMDAwMDAwNTYxMzc1LDAwMDAwMDAwMDAwMCwyCjUsMjIwLDEyMzQ1Njc4OTAxMjM0NTY3LFBQRCxQQVlST0xMLDI0MDkxNiwwMDAwMDA0MjQxMjUsMDAwMDAwMDAwMDAwLDI0MDYwMSwwMDAxCjYsMjIsNDY0Mzg3NDM3MzgsMjc4MjkxMzc5MTgzLDAwMDA0MjQxMjUsYTA0RDYwMDAwMDU4OFJ1LEpPSE4gRE9FLDI0MDYwMTAwMDEsCjUsMjIwLDEyMzQ1Njc4OTAxMjM0NTY3LFBQRCxQQVlST0xMLDI0MDkxNiwwMDAwMDAxMzcyNTAsMDAwMDAwMDAwMDAwLDI0MDcwMSwwMDAxCjYsMjIsNDY0Mzg3NDM3MzgsMjc4MjkxMzc5MTgzLDAwMDAxMzcyNTAsYTA0RDYwMDAwMDU4OFJ1LEpPSE4gRE9FLDI0MDcwMTAwMDEsCg==",
  // };

  return (
    <>
      <Header />
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />

      <div className="container custom-container my-5">
        <div className="row justify-content-center my-4">
          <div className="col-md-6">
            <h3 className="mb-4 text-center">Payment File Generation</h3>
            <div className="box p-4">
              <div className="mb-3">
                <label htmlFor="amount" className="form-label">
                  Max File Amount for Payment
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="amount"
                  placeholder="Enter here"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
              </div>
              {/* <div className="mb-3">
                <label htmlFor="monthToExclude" className="form-label">
                  Month/Year to exclude
                </label>
                <input
                  type="month"
                  className="form-control"
                  id="monthToExclude"
                  value={monthToExclude}
                  onChange={(e) => setMonthToExclude(e.target.value)}
                  required
                />
              </div> */}
              <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center">
                  <label htmlFor="monthToExclude" className="form-label">
                    Month/Year to exclude
                  </label>
                  <i
                    class="fa fa-plus icon-button mb-3 mt-1"
                    aria-hidden="true"
                    onClick={handleAddMonth}
                  ></i>
                </div>

                {selectedMonths.map((month, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <input
                      type="month"
                      className="form-control"
                      value={month}
                      onChange={(e) => handleMonthChange(index, e.target.value)}
                      required
                      style={{ marginRight: "10px" }}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() => handleRemoveMonth(index)}
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                ))}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-dark text-white"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span>
                      Submitting...{" "}
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    </span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentScriptRun;
