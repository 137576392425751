import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import { APIEndpoints } from "./APIEndpoints";
import { Card } from "react-bootstrap";
import Header from "./Header";
import axios from "axios";
import {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
  removeLocalStorageItem,
  getTimeElapsedFromLocalStorage,
} from "./localStorageUtil";
import LastUpdatedTime from "./LastUpdatedTime";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function TherapistRegions(props) {
  const [loading, setLoading] = useState(false);
  const [cityData, setCityData] = useState({});
  const [selectedPlaces, setSelectedPlaces] = useState({});
  const [apiData, setApiData] = useState(null);

  const [lastUpdatedTime, setLastUpdatedTime] = useState(0);

  useEffect(() => {
    const value = getTimeElapsedFromLocalStorage("therapistRegions");
    setLastUpdatedTime(value);
  }, []);

  // Update regions state and functionality
  const [updatingRegions, setUpdatingRegions] = useState(false);
  const [selectedRegionKeys, setSelectedRegionKeys] = useState({});

  const initialFetchData = async () => {
    if (getLocalStorageWithExpiration("therapistRegions")) {
      setLoading(true);
      const cookieData = getLocalStorageWithExpiration("therapistRegions");
      setApiData(cookieData);
      if (cookieData && cookieData.unselected_therapist_regions) {
        const cityDataResponse = cookieData.unselected_therapist_regions;
        setCityData(cityDataResponse);
        const selected_therapist_regions = cookieData.all_available_regions;
        setSelectedPlaces(selected_therapist_regions);
      }
      setLoading(false);
    } else {
      try {
        setLoading(true);
        const cookieData = Cookies.get("logindata");
        const token = cookieData ? JSON.parse(cookieData) : null;
        if (token === null){
            handle401Response();
        }
        const { access_token, sf_id } = token;
        const response = await fetch(
          APIEndpoints.GET_SELECTED_REGIONS + `${sf_id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': `Bearer ${access_token}`,
            },
          }
        );

        if (response.status === 401) {
          // Handle 401 Unauthorized
          handle401Response();
          return;
        }

        const api_data = await response.json();
        setApiData(api_data);

        // Set cookie data
        setLocalStorageWithExpiration("therapistRegions", api_data, 30);

        if (api_data && api_data.unselected_therapist_regions) {
          const cityDataResponse = api_data.unselected_therapist_regions;
          setCityData(cityDataResponse);
          const selected_therapist_regions = api_data.all_available_regions;
          setSelectedPlaces(selected_therapist_regions);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    initialFetchData();
  }, []);

  const showRegionDiv = (id) => {
    const element = document.getElementById(id);

    if (element) {
      if (element.style.display === "") {
        element.style.setProperty("display", "none", "important");
      }
      if (element.style.display === "none") {
        element.style.setProperty("display", "flex", "important");
        element.style.setProperty("flex-wrap", "wrap"); // Show the element
      } else {
        element.style.setProperty("display", "none", "important"); // Hide the element
      }
    }
  };

  const changeButtonColor = (regionName, regionKey, availability_id) => {
    const buttonId = `region-${regionKey}`;
    const button = document.getElementById(buttonId);

    if (button) {
      const updatedSelectedRegionKeys = { ...selectedRegionKeys };

      if (updatedSelectedRegionKeys.hasOwnProperty(regionKey)) {
        // Toggle the value if regionKey is already present
        updatedSelectedRegionKeys[regionKey] =
          !updatedSelectedRegionKeys[regionKey];
      } else {
        // Set the value based on availability_id
        updatedSelectedRegionKeys[regionKey] =
          availability_id === undefined ? true : false;
      }

      if (updatedSelectedRegionKeys[regionKey]) {
        button.classList.remove("btn-light");
        button.classList.add("btn-primary");
      } else {
        button.classList.remove("btn-primary");
        button.classList.add("btn-light");
      }

      setSelectedRegionKeys(updatedSelectedRegionKeys);
    }
  };

  const updateSelectedRegion = (e) => {
    const isRegionKeysEmpty = selectedRegionKeys.length === 0;
    if (isRegionKeysEmpty) {
      toast.error("Please Select Regions to update.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const cookieData = Cookies.get("logindata");
    const token = cookieData ? JSON.parse(cookieData) : null;
    if (token === null){
        handle401Response();
    }
    const { access_token, sf_id } = token;
    const url = APIEndpoints.UPDATE_SELECTED_REGIONS + `${sf_id}`;
    const requestBody = JSON.stringify({
      selected_region: selectedRegionKeys,
    });

    setUpdatingRegions(true);

    const toastId = toast.info(
      "Please allow a few seconds to update regions...",
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false, // Set autoClose to false to prevent automatic dismissal
      }
    );

    // Remove from local storage for data updateion
    if (getLocalStorageWithExpiration("therapistRegions")) {
      removeLocalStorageItem("therapistRegions", 0);
    }

    axios
      .post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        initialFetchData();
        setSelectedRegionKeys({});
        setUpdatingRegions(false);

        toast.update(toastId, {
          render: "Regions updated successfully",
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        if(err.response.status === 401){
          // handleClearCookie();
          handle401Response();
        }
        console.log(err, "e");
        console.error("Update failed");
        setUpdatingRegions(false);
        toast.update(toastId, {
          render: "Failed to update Regions",
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setUpdatingRegions(false);
      });
  };

  return (
    <>
      <Header />
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />
      {Object.keys(selectedPlaces).length === 0 && loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          {" "}
          <div className="spinner-border" role="status">
            {" "}
            <span className="sr-only">Loading...</span>{" "}
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid custom-container">
            <div className="mt-4 justify-content-center">
              <div className="container mt-4">
                <div className="row my-4 mx-auto">
                  <div className="col-12 ">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <h4 className="text-center">
                          <u>Manage Regions</u>
                        </h4>

                        <Card className="w-100 mx-auto mt-4">
                          <Card.Body>
                            <div>
                              {Object.entries(selectedPlaces)
                                .reduce((countyGroups, [regionKey, region]) => {
                                  const existingCountyGroup = countyGroups.find(
                                    (group) =>
                                      group.county_name === region.county_name
                                  );
                                  if (existingCountyGroup) {
                                    existingCountyGroup.regionOptions.push({
                                      ...region,
                                      regionKey: regionKey, // Add regionKey as a property
                                    });
                                  } else {
                                    countyGroups.push({
                                      county_name: region.county_name,
                                      regionOptions: [
                                        {
                                          ...region,
                                          regionKey: regionKey, // Add regionKey as a property
                                        },
                                      ],
                                    });
                                  }
                                  return countyGroups;
                                }, [])
                                .sort((a, b) => {
                                  
                                  const countyNameComparison =
                                    a.county_name.localeCompare(b.county_name);
                                  if (countyNameComparison !== 0) {
                                    return countyNameComparison;
                                  }

                                  const selectedCountA = a.regionOptions.filter(
                                    (r) => r.availablity_id !== undefined
                                  ).length;
                                  const selectedCountB = b.regionOptions.filter(
                                    (r) => r.availablity_id !== undefined
                                  ).length;

                                  // Sort by count in descending order
                                  if (selectedCountA !== selectedCountB) {
                                    return selectedCountB - selectedCountA;
                                  }

                                  // If counts are the same, sort alphabetically by county_name
                                  return a.county_name.localeCompare(
                                    b.county_name
                                  );
                                })
                                .map((countyGroup) => (
                                  <div key={countyGroup.county_name}>
                                    <button
                                      className={`cross-buttons border border-dark position-relative btn ${
                                        countyGroup.regionOptions.some(
                                          (region) => region.availablity_id
                                        )
                                          ? "btn-primary"
                                          : "btn-light"
                                      }`}
                                      onClick={() =>
                                        showRegionDiv(countyGroup.county_name)
                                      }
                                    >
                                      {countyGroup.county_name}
                                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                                        {
                                          countyGroup.regionOptions.filter(
                                            (r) =>
                                              r.availablity_id !== undefined
                                          ).length
                                        }
                                      </span>
                                    </button>
                                    <div
                                      className={`d-none ${countyGroup.county_name}`}
                                      id={countyGroup.county_name}
                                    >
                                      {countyGroup.regionOptions.map(
                                        (region) => (
                                          <div
                                            key={
                                              region.availablity_id ||
                                              region.region_name
                                            }
                                            className="mr-2 mb-2"
                                          >
                                            <button
                                              className={`cross-button border border-dark btn btn-${
                                                region.availablity_id
                                                  ? "primary"
                                                  : "light"
                                              } btn-sm`}
                                              id={`region-${region.regionKey}`}
                                              onClick={() =>
                                                changeButtonColor(
                                                  region.region_name,
                                                  region.regionKey,
                                                  region.availablity_id
                                                )
                                              }
                                            >
                                              {region.region_name}
                                            </button>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                            <div className="text-center my-2">
                              <button
                                className="btn btn-dark"
                                onClick={updateSelectedRegion}
                              >
                                {updatingRegions ? (
                                  <span>
                                    Updating...{" "}
                                    <div
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                    ></div>
                                  </span>
                                ) : (
                                  "Update Regions"
                                )}
                              </button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {lastUpdatedTime ? (
        <LastUpdatedTime time={lastUpdatedTime} />
      ) : (
        <LastUpdatedTime time={"0 min ago"} />
      )}
    </>
  );
}

export default TherapistRegions;
