import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { APIEndpoints } from "./APIEndpoints";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { json } from "../json";

function SignatureEmail() {
  const navigate = useNavigate();

  const { sessionId, signature_request_record } = useParams();

  const [expired, setExpired] = useState(false);
  const [responseData, setResponseData] = useState();
  const [showSubmittedDiv, setShowSubmittedDiv] = useState(false); // Adjusted initial state

  const [isLoading, setIsLoading] = useState(true); // Adjusted initial state
  const [loading, setLoading] = useState(false);
  const [sessionData, setSessionData] = useState();
  const [isSessionLoading, setIsSessionLoading] = useState(true); // Adjusted initial state

  const [signatureData, setSignatureData] = useState(null);
  const [file, setFile] = useState();

  const survey = new Model(json);

  const divRef = useRef();

  useEffect(() => {
    const checkVerified = async () => {
      try {
        const response = await axios.get(
          `${APIEndpoints.CHECK_SESSION_EXPIRED}${signature_request_record}/${sessionId}`
        );

        const responseData = response.data;
        const status = responseData.is_expired;
        setResponseData(responseData);
        setExpired(status);
        setIsLoading(false);
      } catch (error) {
        console.error("Error occurred:", error);
        setExpired(true);
        setIsLoading(false);
      }
    };

    checkVerified();
  }, [sessionId, signature_request_record]); // Added dependencies to useEffect

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${APIEndpoints.SESSION_INFO}?session_id=${sessionId}`
        );
        setSessionData(response.data);
        setIsSessionLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsSessionLoading(false);
      }
    };

    fetchData();
  }, [sessionId]); // Added dependencies to useEffect

  const handleGenerate = async (data) => {
    try {
      if (data) {
        const blob = dataURLtoBlob(data);
        const file = new File([blob], "signature.png", { type: "image/png" });
        handleSignUpload(file); // Directly pass the file to handleSignUpload
      }
    } catch (error) {
      console.error("Error occurred during image generation:", error);
    }
  };

  const handleSignUpload = async (file) => {
    try {
      setLoading(true);

      if (!file) {
        toast.error("Please upload signature", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const formData = new FormData();
      formData.append("photo", file);

      const response = await fetch(
        `${APIEndpoints.COMPLETE_SIGNATURE_REQUEST}?session_id=${sessionId}&signature_request_id=${signature_request_record}`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      setLoading(false);
      toast.success("Successfully submitted.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      setShowSubmittedDiv(true);
    } catch (error) {
      setLoading(false);
      toast.error("Error in Sign Upload", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error in Sign Upload:", error);
    }
  };

  const handleClickOutside = () => {
    // Clicked outside of the div element
    const completeButton = document.querySelector(
      ".sd-btn--action.sd-navigation__complete-btn"
    );
    // Check if the element is found
    if (completeButton) {
      // Simulate a click on the element
      completeButton.click();
    } else {
      console.error("Element not found");
    }
  };

  survey.onComplete.add((sender, options) => {
    setSignatureData(sender.data.signature);
    handleGenerate(sender.data.signature);
  });

  // Helper function to convert data URL to Blob
  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  return (
    <>
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />
      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!showSubmittedDiv ? (
            <>
              <div className="container custom-container my-5">
                {expired ? (
                  <div className="text-center">
                    <h4>{responseData.message}</h4>
                    <p>This URL is not valid anymore.</p>
                    {!responseData.policy_signed && (
                      <>
                        <p>But you have not signed the policy yet.</p>
                        <div className="text-center">
                          <Button
                            className="btn btn-info text-white px-4"
                            onClick={() => {
                              navigate(
                                `/policy-procedure/${responseData.child_id}/${responseData.child_contact_id}`
                              );
                            }}
                          >
                            Sign Policy Procedure
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="container-fluid custom-container my-5">
                    <div className="container-fluid my-5 h-100">
                      {sessionData && (
                        <div className="d-flex flex-column justify-content-center align-items-center my-3">
                          <h5 className="my-2">
                            <strong>Session Information</strong>
                          </h5>
                          <div className="p-3 text-center">
                            <p className="mb-0">
                              <span>
                                <b>Therapist:</b>{" "}
                              </span>
                              {sessionData.Therapist__r.Last_Name__c},{" "}
                              {sessionData.Therapist__r.First_Name__c}
                            </p>
                            <p className="mb-0">
                              <span>
                                <b> Service Date: </b>
                              </span>
                              {sessionData.Service_Completed__c.split("T")[0]}
                            </p>
                            <p className="mb-0">
                              <span>
                                <b>Start Time: </b>
                              </span>
                              {sessionData.Start_Time__c.split(".")[0]}
                            </p>
                            <p className="mb-0">
                              <span>
                                <b>End Time:</b>{" "}
                              </span>
                              {sessionData.End_Time__c.split(".")[0]}
                            </p>
                          </div>
                        </div>
                      )}
                      <h5 className="text-center">
                        <b>Add Missing Signature</b>
                      </h5>
                      <div
                        className="container card"
                        style={{ background: "transparent" }}
                      >
                        <div className="row text-center">
                          <div className="mb-3" ref={divRef}>
                            <Survey model={survey} />
                          </div>
                        </div>
                        <div className="row text-center">
                          <div className="col my-2">
                            <Button
                              className="btn btn-info text-white px-4"
                              onClick={handleClickOutside}
                            >
                              {loading ? (
                                <span>
                                  Submitting...{" "}
                                  <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                  ></div>
                                </span>
                              ) : (
                                "Submit"
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              {responseData && (
                <div className="container custom-container my-5 d-flex justify-content-center align-items-center h-100">
                  <div className="">
                    <div className="text-center">
                      <h4>Your signature has been submitted.</h4>
                      {!responseData.policy_signed && (
                        <>
                          <p>But you have not signed the policy yet.</p>
                          <div className="text-center">
                            <Button
                              className="btn btn-info text-white px-4"
                              onClick={() => {
                                navigate(
                                  `/policy-procedure/${responseData.child_id}/${responseData.child_contact_id}`
                                );
                              }}
                            >
                              Sign Policy Procedure
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default SignatureEmail;