import React, { useEffect, useState } from "react";
import Header from "./Header";
import axios from "axios";
import { APIEndpoints } from "./APIEndpoints";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { removeLocalStorageItem } from "./localStorageUtil";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function SendSignatureLink() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(location?.state?.data);
  const [sessionId, setSessionId] = useState(location?.state?.sessionId);
  const [indexClick, setIndexClick] = useState(false);
  const [sessionData, setSessionData] = useState();
  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const [childId, setChildId] = useState();

  useEffect(() => {
    if (!sessionId || !data) {
      navigate("/");
    }
  }, [sessionId, data, navigate]);

  // Initialize sms and email state for each item in data
  const initialToggleState = data ? data.map(() => ({
    smsEnabled: false,
    emailEnabled: false,
  })): [];

  const [toggleStates, setToggleStates] = useState(initialToggleState);

  const sendLink = async (item, index) => {
    setIndexClick(index);
    setIsLoading(true);

    const smsEnabled = toggleStates[index].smsEnabled;
    const emailEnabled = toggleStates[index].emailEnabled;

    if (!smsEnabled && !emailEnabled) {
      toast.error("Select a option first", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      return;
    }

    let url = APIEndpoints.SEND_SIGNATURE_REQUEST;
    if (smsEnabled && emailEnabled) {
      url += `${item.contact_id}/${sessionId}/${item.cell_phone}/${item.Email}/${item.first_name}/${item.last_name}`;
    } else if (smsEnabled) {
      url += `${item.contact_id}/${sessionId}/${item.cell_phone}/None/${item.first_name}/${item.last_name}`;
    } else if (emailEnabled) {
      url += `${item.contact_id}/${sessionId}/None/${item.Email}/${item.first_name}/${item.last_name}`;
    }

    const cookieData = Cookies.get("logindata");
    const token = cookieData ? JSON.parse(cookieData) : null;
    if (token === null){
        handle401Response();
    }
    const { access_token } = token;

    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`,
          },
        }
      );

      setIsLoading(false);
      if (response.status === 200) {
        const response_msg = response.data.message;
        if (response_msg.includes(".")) {
          const split_msg = response_msg.split(".");
          toast.success(split_msg[0], {
            position: toast.POSITION.TOP_RIGHT,
          });
          toast.error(split_msg[1], {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.success(response_msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        // Delete some things from local storage
        removeLocalStorageItem("billableData", 5);
        setTimeout(() => {
          navigate("/");
        }, 5000);
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigate("/");
        }, 5000);
      }
    } catch (error) {
      if(error.response.status === 401){
        handle401Response();
      }
      const errorMessage = error.response.data.message;
      setIsLoading(false);
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        if (!sessionId) {
          navigate("/");
          return
        }

        setIsSessionLoading(true);
        const cookieData = Cookies.get("logindata");
        const token = cookieData ? JSON.parse(cookieData) : null;
        if (token === null){
            handle401Response();
        }
        const { access_token } = token;
        const response = await axios.get(
          `${APIEndpoints.SESSION_INFO}?session_id=${sessionId}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          }
        );
        setSessionData(response.data);
        setChildId(response.data.childId);
        setIsSessionLoading(false);
      } catch (error) {
        if(error.response.status === 401){
          handle401Response();
        }  
        console.error("Error fetching data:", error);
        setIsSessionLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Header />
      {isSessionLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <ToastContainer style={{ top: "7rem", position: "fixed" }} />
          <div className="container custom-container my-5 ">
            {sessionData && (
              <div className="d-flex flex-column justify-content-center align-items-center my-3">
                <h5 className="my-2">
                  <strong>Session Information</strong>
                </h5>
                <div className=" p-3 text-center">
                  <p className="mb-0">
                    <span>
                      <b>Therapist:</b>{" "}
                    </span>
                    {sessionData.Therapist__r.Last_Name__c},{" "}
                    {sessionData.Therapist__r.First_Name__c}
                  </p>
                  <p className="mb-0">
                    <span>
                      <b> Service Date: </b>
                    </span>
                    {sessionData.Service_Completed__c.split("T")[0]}
                  </p>
                  <p className="mb-0">
                    <span>
                      <b>Start Time: </b>
                    </span>
                    {sessionData.Start_Time__c.split(".")[0]}
                  </p>
                  <p className="mb-0">
                    <span>
                      <b>End Time:</b>{" "}
                    </span>
                    {sessionData.End_Time__c.split(".")[0]}
                  </p>
                </div>
              </div>
            )}

            {data && data.map((item, index) => (
              <div
                key={index}
                className="box d-flex flex-column justify-content-center align-items-center my-3"
              >
                <div className=" p-3 text-center">
                  <p className="mb-0">
                    <span>Name: </span>
                    {item.first_name + " " + item.last_name}
                  </p>
                  <p className="mb-0">
                    <span>Phone: </span>
                    {item.cell_phone}
                  </p>
                  <p className="mb-0">
                    <span>Email: </span>
                    {item.Email}
                  </p>
                  <p className="mb-0">
                    <span>Relation: </span>
                    {item.relation}
                  </p>
                </div>

                <div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`flexSwitchSms${index}`} // Unique ID for SMS checkbox
                      checked={toggleStates[index].smsEnabled} // Use individual toggle state
                      onChange={() => {
                        const updatedToggleStates = [...toggleStates];
                        updatedToggleStates[index] = {
                          ...updatedToggleStates[index],
                          smsEnabled: !updatedToggleStates[index].smsEnabled,
                        };
                        setToggleStates(updatedToggleStates);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexSwitchSms${index}`}
                    >
                      Send SMS
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`flexSwitchEmail${index}`} // Unique ID for Email checkbox
                      checked={toggleStates[index].emailEnabled} // Use individual toggle state
                      onChange={() => {
                        const updatedToggleStates = [...toggleStates];
                        updatedToggleStates[index] = {
                          ...updatedToggleStates[index],
                          emailEnabled:
                            !updatedToggleStates[index].emailEnabled,
                        };
                        setToggleStates(updatedToggleStates);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexSwitchEmail${index}`}
                    >
                      Send Email
                    </label>
                  </div>
                </div>
                <div className="p-3">
                  <button
                    type="button"
                    className="btn btn-dark text-white"
                    onClick={() => sendLink(item, index)}
                  >
                    {isLoading && indexClick === index ? (
                      <span>
                        Sending{" "}
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        ></div>
                      </span>
                    ) : (
                      "Request Signature"
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default SendSignatureLink;
