import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { APIEndpoints } from "./APIEndpoints";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function FilledEIMSForm() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState(location?.state?.name);
  const [sessionId, setSessionId] = useState(location?.state?.sessionId);
  const [data, setData] = useState();
  const [file, setFile] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        if(!name || !sessionId){
          navigate("/");
          return
        }

        setIsLoading(true);
        const cookieData = Cookies.get("logindata");
        const token = cookieData ? JSON.parse(cookieData) : null;
        if (token === null){
            handle401Response();
        }
        const { access_token } = token;

        const response = await axios.get(
          `${APIEndpoints.GET_SESSION_DETAILS}${sessionId}/`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          }
        );
        setData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        if(error.response.status === 401){
          handle401Response();
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [sessionId]);

  useEffect(() => {
    if (data) {
      handleGenerate();
    }
  }, [data]);

  const handleGenerate = () => {
    try {
      const binaryString = atob(data?.sign);
      const imageData = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        imageData[i] = binaryString.charCodeAt(i);
      }
      const url = URL.createObjectURL(new Blob([imageData]));
      setFile(url);
    } catch (error) {
      console.error("Error occurred during image generation:", error);
    }
  };

  const formatDate = (isoDate) => {
    if (data) {
      const date = new Date(isoDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

  const formatTime = (isoTime) => {
    if (data) {
      const [hours, minutes] = isoTime.split(":");
      return `${hours}:${minutes}`;
    }
  };

  return (
    <>
      <Header />
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />

      {(isLoading || !file) && (
        <div className="loader-container text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}

      {!isLoading && file && (
        <>
          <div className="container-fluid custom-container my-5">
            <h4 className="text-center">
              Here is your submitted session information
            </h4>
            <div className="container-fluid my-1 h-100">
              <div
                className="container card my-4"
                style={{ background: "transparent" }}
              >
                <div className="row p-2 text-center">
                  <h5>{name}</h5>
                </div>
                <hr className="m-0" />
                <div className="row my-4">
                  <form>
                    <div className="mb-1 row">
                      <p>
                        <span className="me-2 fw-bold">Service Status : </span>
                        {data?.service_status}
                      </p>
                    </div>

                    <div className="mb-1 row">
                      <p>
                        <span className="me-2 fw-bold">Location : </span>
                        {data?.Service_Location_Codes__c}
                      </p>
                    </div>

                    <div className="mb-1 row">
                      <p>
                        <span className="me-2 fw-bold">Service Type : </span>
                        {data?.Service_Ty__c}
                      </p>
                    </div>

                    <div className="mb-1 row">
                      <p>
                        <span className="me-2 fw-bold">Service Date : </span>
                        {formatDate(data?.Service_Completed__c)}
                      </p>
                    </div>

                    <div className="mb-1 row">
                      <p>
                        <span className="me-2 fw-bold"> End Time : </span>
                        {formatTime(data?.Start_Time__c)}
                      </p>
                    </div>

                    <div className="mb-1 row">
                      <p>
                        <span className="me-2 fw-bold"> End Time : </span>
                        {formatTime(data?.End_Time__c)}
                      </p>
                    </div>

                    <div className="mb-1 row">
                      <p>
                        <span className="me-2 fw-bold"> Note : </span>
                        {data?.Note__c}
                      </p>
                    </div>

                    <div className="mb-1 row">
                      <p>
                        <span className="me-2 fw-bold">
                          {" "}
                          Responsible Party Available :{" "}
                        </span>
                        {data?.Responsible_Party_Unavailable__c ? "Yes" : "No"}
                      </p>
                    </div>

                    {data?.Responsible_Party_Unavailable__c && data?.sign && (
                      <div className="mb-1 row">
                        <p className="col-4">
                          <span className="fw-bold">
                            {" "}
                            Responsible Party Signature :{" "}
                          </span>
                        </p>
                        <img
                          src={file}
                          alt="Preview"
                          className="border border-1 col-7 offset-1 m-2"
                        />
                      </div>
                    )}

                    <hr />

                    <div className="mb-1 row text-center">
                      <div>
                        <button
                          className="btn btn-dark"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Go to Home
                        </button>
                        <button
                          className="btn btn-dark ms-2"
                          onClick={() => {
                            navigate("/child");
                          }}
                        >
                          Back To Children
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default FilledEIMSForm;
