import React, { useEffect, useState } from "react";
import Header from "./Header";
import { APIEndpoints } from "./APIEndpoints";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function ParentContactForm() {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();
    const [childID, setChildId] = useState(location?.state?.childID);
    const [therapistServiceId, setTherapistServiceId] = useState(
        location?.state?.therapistServiceId
    );
    const [serviceId, setServiceId] = useState(location?.state?.service_id);
    const navigate = useNavigate()

    useEffect(() => {
      if (!childID || !therapistServiceId || !serviceId) {
        navigate("/");
        return
      }
    }, [childID, therapistServiceId, serviceId, navigate]);

    const fetchDescriptionOptions = async () => {
        try {

            if (!childID) {
              navigate("/");
              return;
            }

            const cookieData = Cookies.get("logindata");
            const token = cookieData ? JSON.parse(cookieData) : null;
            if (token === null){
                handle401Response();
            }
            const { access_token } = token;

            setLoading(true);
            const response = await axios.get(
                `${APIEndpoints.PARENT_CHILD_CONTACTS}${childID}`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    }
                }
            );
            setData(response.data);
            setLoading(false);
        } catch (error) {
            if(error.response.status === 401){
                handle401Response();
            }
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchDescriptionOptions();
    }, []);

    const handelSubmit = async (event) => {
        event.preventDefault();

        const cookieData = Cookies.get("logindata");
        const token = cookieData ? JSON.parse(cookieData) : null;
        if (token === null){
            handle401Response();
        }
        const { access_token, sf_id } = token;

        const formData = new FormData(event.target);

        const requestBody = {
            therapist_service_id: therapistServiceId,
            child_service_id: serviceId,
            child_contact_id: formData.get("child_contact"),
            child_contact_method: formData.get("contact_method"),
            scheduled_start_date: formData.get("scheduled_start_date"),
            note: formData.get("note"),
            therapist_id: sf_id,
            contact_date: formData.get("contact_date")
        };

        try {
            setIsLoading(true);
            const response = await fetch(`${APIEndpoints.PARENT_CHILD_CONTACTS}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${access_token}`,
                },
                body: JSON.stringify(requestBody),
            });

            if (response.status === 401) {
                // Handle 401 Unauthorized
                handle401Response();
                return;
            }
      

            const data = await response.json();
            event.target.reset();
            setIsLoading(false);
            if(response.status === 200){
                toast.success(data?.message, { position: toast.POSITION.TOP_RIGHT });
            }else{
                toast.error("Raise Some Error", { position: toast.POSITION.TOP_RIGHT });
            }
            setTimeout(() => {
                navigate('/');
            }, 2000);
        } catch (error) {
            setIsLoading(false);
            event.target.reset();
            toast.error("Raise Some Error", { position: toast.POSITION.TOP_RIGHT });
            console.error("Error:", error);
        }
    };

    return (
        <>
            <ToastContainer />
            <Header />
            {loading ? (
                <div className="detail-page container-fluid">
                    <div className="container my-4">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container custom-container my-5">
                    <div className="container-fluid my-5 h-100">
                        <div
                            className="container card my-4"
                            style={{ background: "transparent" }}
                        >
                            <div className="row p-2 text-center">
                                <h5>Parent Contact Form</h5>
                            </div>
                            <hr className="m-0" />
                            <div className="row my-4">
                                <form onSubmit={handelSubmit}>
                                    <div className="mb-2 row">
                                        <label className="col-form-label">Child Contact</label>
                                    </div>
                                    <div className="mb-2">
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            name="child_contact"
                                            required
                                        >
                                            <option value="">Select a child contact</option>
                                            {data &&
                                                data.map((child) => (
                                                    <option key={child.id} value={child.id}>
                                                        {child.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <label className="col-form-label">Contact Date</label>
                                        <div className="mb-2">
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="contact_date"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-2 row">
                                        <label className="col-form-label">Contact Method</label>
                                    </div>
                                    <div className="mb-2">
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            name="contact_method"
                                        >
                                            <option value="">Select a contact method</option>
                                            <option value="Text">Text</option>
                                            <option value="Call">Call</option>
                                            <option value="Email">Email</option>
                                        </select>
                                    </div>
                                
                                    <div className="mb-4">
                                        <label className="col-form-label">Note</label>
                                        <div className="mb-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="serviceDate"
                                                name="note"
                                                placeholder="Enter Note"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-2">
                                        <label className="col-form-label">Scheduled Start Date (*Only enter if there is a confirmed start date)</label>
                                        <div className="mb-2">
                                            <input
                                                type="date"
                                                className="form-control"
                                                name="scheduled_start_date"
                                            />
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        {isLoading ? (
                                            <Button variant="dark" disabled>
                                                Submitting...
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </Button>
                                        ) : (
                                            <input
                                                type="submit"
                                                // style={{ width: '30%' }}
                                                className="btn btn-dark text-white"
                                                value="Submit"
                                            />
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ParentContactForm;
