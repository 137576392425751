import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APIEndpoints } from "./APIEndpoints";
import {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
} from "./localStorageUtil";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function Form13Task() {

  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [submittingTask, setSumittingTask] = useState(false);
  const [submittingTaskIndex, setSubmittingTaskIndex] = useState(null);

  const fetchData = async () => {
    if (getLocalStorageWithExpiration("task-data")) {
      setIsLoading(true);
      const cookieData = getLocalStorageWithExpiration("task-data");
      setData(cookieData);
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const cookieData = Cookies.get("logindata");
        const token = cookieData ? JSON.parse(cookieData) : null;
        if (token === null) {
          handle401Response();
        }
        const { access_token, sf_id } = token;
        const response = await axios.get(`${APIEndpoints.ALL_TASK}${sf_id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          }
        );
        setData(response.data);
        // Set Data in Cookie for 30 minutes
        setLocalStorageWithExpiration("task-data", response.data, 30);
        setIsLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          handle401Response();
        }
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const submitTask = async (taskid, therapist_service_id) => {
    try {
      setSumittingTask(true);
      setSubmittingTaskIndex(taskid);
      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null) {
        handle401Response();
      }
      const { access_token } = token;
      const response = await axios.get(
        `${APIEndpoints.FORM_13_TASK}${taskid}/${therapist_service_id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`,
          },
        }
      );
      setSubmittingTaskIndex(null);
      setSumittingTask(false);

      if (response.status === 200) {
        toast.success(response?.data?.message || "Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response?.data?.message || "Failed to create task", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      if (error.response.status === 401) {
        handle401Response();
      }
      setSumittingTask(false);
      setSubmittingTaskIndex(null);
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />
      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {data && data?.form13task && data?.form13task?.length > 0 ? (
            <div className="container custom-container my-5">
              <div className="my-2">
                <p className="mb-0">
                  <strong>Form 13s</strong>
                </p>
                <p>
                  Please aim to complete form 13 30 days before the current IFSP
                  service end time. Once you've entered Form 13 in the EIMS, click
                  the "Form 13 submitted" button below so we can review it in the
                  EIMS.
                </p>
              </div>
              {data.form13task.map((task, index) => (
                <div
                  className="box d-flex flex-wrap gap-2 justify-content-between p-3 mb-3 rounded"
                  key={index}
                >
                  <div>
                    <p className="ms-2 mb-0">
                      <strong>{task?.child_service_name.split('-')[0]}</strong>
                    </p>
                    <p className="ms-2 mb-0">
                      IFSP End Date: {task?.ifsp_end_date}
                    </p>
                    <p className="ms-2 mb-0 text-danger">
                      Due Date: {task?.due_date}
                    </p>
                  </div>
                  <div className="my-auto">
                    <button
                      type="button"
                      className="btn btn-sm text-white py-3"
                      style={{ backgroundColor: "#06235c" }}
                      onClick={() => {
                        submitTask(task?.id, task?.therapist_service_id);
                      }}
                    >
                      {submittingTaskIndex === task?.id ? (
                        <div>
                          Form 13 Submitted
                          <div
                            className="ms-2 spinner-border text-light"
                            style={{ width: "1rem", height: "1rem" }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        " Form 13 Submitted"
                      )}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </>
      )
      }
    </>
  );
}

export default Form13Task;
