import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import { APIEndpoints } from "./APIEndpoints";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handle401Response } from "./authHelper";
import Cookies from 'js-cookie';

function ConsentContacts() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const [indexClick, setIndexClick] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [childId, setChildId] = useState(
    location?.state?.childId
  );
  const [childServiceId, setChildServiceId] = useState(
    location?.state?.childServiceId
  );
  
  const [therapistServiceId, setTherapistServiceId] = useState(
    location?.state?.therapistServiceId
  );

  const [contacts, setContacts] = useState([]);

  const fetchContactData = async () => {
    try {

      if (!therapistServiceId || !childServiceId || !childId) {
        navigate("/");
        return;
      }

      setIsLoading(true);
      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null){
          handle401Response();
      }
      const { access_token } = token;

      const response = await axios.post(
        APIEndpoints.CHILD_ALL_CONTACTS,
        {
          therapist_service_id: therapistServiceId,
          child_service_id: childServiceId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`,
          },
        }
      );

      const data = response.data;
      const child_contacts = data.data.child_contacts;
      setContacts(data.data.child_contacts);
      const initialToggleState = child_contacts.map(() => ({
        smsEnabled: false,
        emailEnabled: false,
      }));
      setToggleStates(initialToggleState);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
      if(error.response.status === 401){
        handle401Response();
      }
    }
  };

  useEffect(() => {
    fetchContactData();
  }, []);

  // Initialize sms and email state for each item in data

  const [toggleStates, setToggleStates] = useState([]);

  const sendLink = async (item, index) => {
    setIndexClick(index);
    setIsSending(true);
    const smsEnabled = toggleStates[index].smsEnabled;
    const emailEnabled = toggleStates[index].emailEnabled;

    if (!smsEnabled && !emailEnabled) {
      toast.error("Select a option first", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsSending(false);
      return;
    }

    const cookieData = Cookies.get("logindata");
    const token = cookieData ? JSON.parse(cookieData) : null;
    if (token === null){
        handle401Response();
    }
    const { access_token } = token;

    try {
      const response = await axios.post(
        `${APIEndpoints.CONSENT_FORM_LINK}`,
        {
          contactId: item.Id,
          emailEnabled: emailEnabled,
          phoneEnabled: smsEnabled,
          childId: childId,
          therapistServiceId:therapistServiceId,
          email: item.email,
          phone: item.phone,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`
          },
        }
      );

      setIsSending(false);
      if (response.status === 200) {
        const response_msg = response.data.message;
        if (response_msg.includes(".")) {
          const split_msg = response_msg.split(".");
          if (split_msg[0].includes("Failed")) {
            toast.error(split_msg[0], {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.success(split_msg[0], {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          if (split_msg[1].includes("Failed")) {
            toast.error(split_msg[1], {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.success(split_msg[1], {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          if (response_msg.includes("Failed")) {
            toast.error(response_msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.success(response_msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      if(error.response.status === 401){
        handle401Response();
      }
      const errorMessage = error.response.data.message;
      setIsSending(false);
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  };

  return (
    <>
      <Header />
      <ToastContainer />
      {isloading ? (
        <div className="detail-page container-fluid">
          <div className="container my-4">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container custom-container my-5">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h5 className="mb-4 text-center">Child Contacts</h5>
              {contacts.length > 0 && toggleStates.length > 0 ? (
                <div className="w-100">
                  {contacts.map((item, index) => (
                    <div
                      key={index}
                      className="box d-flex flex-column justify-content-center align-items-center my-3"
                    >
                      <div className=" p-3 text-center">
                        <p className="mb-0">
                          <span>Name: </span>
                          {item.name}
                        </p>
                        <p className="mb-0">
                          <span>Phone: </span>
                          {item.phone}
                        </p>
                        <p className="mb-0">
                          <span>Email: </span>
                          {item.email}
                        </p>
                        <p className="mb-0">
                          <span>Relation: </span>
                          {item.relation}
                        </p>
                      </div>

                      <div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`flexSwitchSms${index}`} // Unique ID for SMS checkbox
                            checked={toggleStates[index].smsEnabled} // Use individual toggle state
                            onChange={() => {
                              const updatedToggleStates = [...toggleStates];
                              updatedToggleStates[index] = {
                                ...updatedToggleStates[index],
                                smsEnabled:
                                  !updatedToggleStates[index].smsEnabled,
                              };
                              setToggleStates(updatedToggleStates);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexSwitchSms${index}`}
                          >
                            Send SMS
                          </label>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`flexSwitchEmail${index}`} // Unique ID for Email checkbox
                            checked={toggleStates[index].emailEnabled} // Use individual toggle state
                            onChange={() => {
                              const updatedToggleStates = [...toggleStates];
                              updatedToggleStates[index] = {
                                ...updatedToggleStates[index],
                                emailEnabled:
                                  !updatedToggleStates[index].emailEnabled,
                              };
                              setToggleStates(updatedToggleStates);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexSwitchEmail${index}`}
                          >
                            Send Email
                          </label>
                        </div>
                      </div>
                      <div className="p-3">
                        <button
                          type="button"
                          className="btn btn-dark text-white"
                          onClick={() => sendLink(item, index)}
                        >
                          {isSending && indexClick === index ? (
                            <span>
                              Sending{" "}
                              <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                              ></div>
                            </span>
                          ) : (
                            "Request Signature"
                          )}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                "No data to show"
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ConsentContacts;
