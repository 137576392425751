import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { APIEndpoints } from "./APIEndpoints";
import {
    setLocalStorageWithExpiration,
    getLocalStorageWithExpiration,
} from "./localStorageUtil";
import axios from "axios";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function QuizTypes() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const fetchData = async () => {
        if (getLocalStorageWithExpiration("task-data")) {
            setIsLoading(true);
            const cookieData = getLocalStorageWithExpiration("task-data");
            setData(cookieData.trainings);
            setIsLoading(false);
        } else {
            try {
                setIsLoading(true);
                const cookieData = Cookies.get("logindata");
                const token = cookieData ? JSON.parse(cookieData) : null;
                if (token === null) {
                    handle401Response();
                }
                const { access_token, sf_id } = token;
                const response = await axios.get(`${APIEndpoints.ALL_TASK}${sf_id}`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    }
                }
                );
                setData(response.data.trainings);
                // Set Data in Cookie for 30 minutes
                setLocalStorageWithExpiration("task-data", response.data, 30);
                setIsLoading(false);
            } catch (error) {
                if (error.response.status === 401) {
                    handle401Response();
                }
                console.error("Error fetching data:", error);
                setIsLoading(false);
            }
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Header />
            {isLoading ? (
                <div className="">
                    <div className="card-body text-center">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container custom-container my-5">
                    <div className="row my-4">
                        {data !== null ? (<>
                            {data.length > 0 ? (
                                <>
                                    {data.map((item) => (
                                        <div
                                            key={item.id}
                                            className="box d-flex justify-content-between p-3 mb-3 rounded"
                                            onClick={(e) => {
                                                // navigate('/training-video', {
                                                //     state: {
                                                //         type: item.type,
                                                //         id: item.id
                                                //     },
                                                // });
                                                if (item.type === "Fraud, Waste, and Abuse") {
                                                    navigate('/fwp-quiz', {
                                                        state: {
                                                            type: item.type,
                                                            id: item.id
                                                        },
                                                    });
                                                }

                                                if (item.type === "Universal Precautions") {
                                                    navigate('/universal-quiz', {
                                                        state: {
                                                            type: item.type,
                                                            id: item.id
                                                        },
                                                    });
                                                }
                                            }}
                                        >
                                            <div>
                                                <p className="ms-2">{item.type}</p>
                                            </div>
                                            <div className="my-auto">
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <p className="text-center">No Therapist trainings to process</p>
                            )}
                        </>) : (<p className="text-center">No Therapist trainings to process</p>)}

                    </div>
                </div>)}
        </>
    );
}

export default QuizTypes;

