import React, { useEffect, useState } from "react";
import Header from "./Header";
import { APIEndpoints } from "./APIEndpoints";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function PayStub() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [unpaidData, setUnpaidData] = useState({});
  const [processedData, setProcessedData] = useState({});
  const [downloadPayData, setDownloadPayDate] = useState();
  const [downloading, setDownloading] = useState(false);
  const [downloadingAll, setDownloadingAll] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null){
          handle401Response();
      }
      const { access_token, sf_id } = token;
      const response = await axios.get(`${APIEndpoints.PayStubb}${sf_id}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        }
      );
      if (response.status === 200) {
        setUnpaidData(response?.data?.unpaid_data);
        setProcessedData(response?.data?.processed_data?.data);
        setIsLoading(false);
      } else {
        toast.success("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      if(error.response.status === 401){
        handle401Response();
      }
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function downloadFile(e, pay_date) {
    e.stopPropagation();
    const cookieData = Cookies.get("logindata");
    const token = cookieData ? JSON.parse(cookieData) : null;
    if (token === null){
        handle401Response();
    }
    const { access_token, sf_id } = token;
    setDownloadPayDate(pay_date);
    setDownloading(true);
    axios
      .get(`${APIEndpoints.DownloadPayStub}${sf_id}/${pay_date}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        responseType: "json", // or 'text' depending on your API's response type
      })
      .then((response) => {
        const base64Data = response.data.file;
        const binaryString = atob(base64Data);
        const byteArray = Uint8Array.from(binaryString, (char) =>
          char.charCodeAt(0)
        );
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "TA PayStub.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
        setDownloading(false);
      })
      .catch((error) => {
        setDownloading(false);
        if(error.response.status === 401){
          handle401Response();
        }
        console.error("Error downloading file:", error);
      });
  }

  function downloadCurrentYearFile(e) {
    e.stopPropagation();
    const cookieData = Cookies.get("logindata");
    const token = cookieData ? JSON.parse(cookieData) : null;
    if (token === null){
        handle401Response();
    }
    const { access_token, sf_id } = token;
    setDownloadingAll(true);
    axios
      .get(`${APIEndpoints.DownloadYearlyPayStub}${sf_id}/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        responseType: "json", // or 'text' depending on your API's response type
      })
      .then((response) => {
        const base64Data = response.data.file;
        const binaryString = atob(base64Data);
        const byteArray = Uint8Array.from(binaryString, (char) =>
          char.charCodeAt(0)
        );
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "TA PayStub.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
        setDownloadingAll(false);
      })
      .catch((error) => {
        setDownloadingAll(false);
        console.error("Error downloading file:", error);
        if(error.response.status === 401){
          handle401Response();
        }
      });
  }

  return (
    <>
      <ToastContainer />
      <Header />
      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid custom-container my-5">
          <div className="container-fluid my-5 h-100">
            <div>
              <p className="mb-3 font-weight-bold px-2">Unpaid Sessions</p>
            </div>
            {unpaidData?.unpaid_session_count > 0 ? (
              <div
                className="box d-flex justify-content-between p-3 mb-3 rounded"
                style={{ background: "#0A1E4F", color: "white" }}
                onClick={() => {
                  navigate("/unpaid-sessions");
                }}
              >
                <div>
                  <h5>
                    <strong>
                      ${unpaidData?.unpaid_amount} unpaid for{" "}
                      {unpaidData?.unpaid_session_count}{" "}
                      {unpaidData?.unpaid_session_count === 1
                        ? "session"
                        : "sessions"}
                    </strong>
                  </h5>
                  <p>These sessions have not yet been paid</p>
                </div>
                <div className="">
                  <i className="fa-solid fa-arrow-right"></i>
                </div>
              </div>
            ) : (
              <div className="text-center my-4">
                <p>No unpaid sessions found</p>
              </div>
            )}

            <div className="d-flex justify-content-between my-2">
              <p className="mb-3 font-weight-bold px-2">Processed Payments</p>
              <button
                className="btn btn-outline-danger"
                onClick={(e) => downloadCurrentYearFile(e)}
                disabled={downloadingAll}
              >
                { downloadingAll ? (
                  <div
                    className="spinner-border spinner-border-sm mx-5"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    <i
                      class="fa fa-long-arrow-down mx-2"
                      aria-hidden="true"
                    ></i>
                    YTD Paystub Download
                  </>
                )}
              </button>
            </div>

            {Array.isArray(processedData) && processedData.length > 0 ? (
              processedData.map((item, index) => (
                <div
                  className="box d-flex justify-content-between flex-wrap p-3 mb-3 rounded"
                  key={index}
                  onClick={() => {
                    navigate("/processed-sessions", {
                      state: {
                        totalAmount: item?.total_amount,
                        totalSessions: item?.total_sessions,
                        processDate: item?.pay_date,
                        formattedPayDate: item?.formatted_pay_date,
                      },
                    });
                  }}
                >
                  <div>
                    <h5>
                      <strong>
                        ${item?.total_amount} paid for {item?.total_sessions}{" "}
                        {item?.total_sessions === 1 ? "session" : "sessions"}
                      </strong>
                    </h5>
                    <p>Process Date: {item?.formatted_pay_date}</p>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-outline-danger"
                      onClick={(e) => downloadFile(e, item?.pay_date)}
                      disabled={
                        downloading && downloadPayData === item?.pay_date
                      }
                    >
                      {downloading && downloadPayData === item?.pay_date ? (
                        <div
                          className="spinner-border spinner-border-sm mx-5"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          <i
                            class="fa fa-long-arrow-down mx-2"
                            aria-hidden="true"
                          ></i>
                          Download
                        </>
                      )}
                    </button>
                    <i className="fa-solid fa-arrow-right ms-4"></i>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center my-4">
                <p>No processed payments found</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PayStub;
