import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import Cookies from 'js-cookie';

function PrivateComponent() {
    const token = Cookies.get("logindata");

  useEffect(() => {}, [token]);
  return token ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateComponent;
