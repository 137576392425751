import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import { APIEndpoints } from './APIEndpoints';
import { Card } from 'react-bootstrap';
import Header from './Header';
import { setLocalStorageWithExpiration, getLocalStorageWithExpiration, removeLocalStorageItem, getTimeElapsedFromLocalStorage } from './localStorageUtil';
import LastUpdatedTime from './LastUpdatedTime';
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const times = ['7 AM','8 AM','9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM'];

function TherapistAvailability() {

  const [selectedCells, setSelectedCells] = useState({});
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const [lastUpdatedTime, setLastUpdatedTime] = useState(0);

  useEffect(() => {
    const value = getTimeElapsedFromLocalStorage('therapistAvailability');
    setLastUpdatedTime(value);
  },[]);


  const toggleCell = (event, day, time) => {
    const cellKey = `${day}-${time}`;
    const prevKey = selectedCells[cellKey];

    if (prevKey) {
      selectedCells[cellKey] = false;
      event.className = '';
    } else {
      selectedCells[cellKey] = true;
      event.className = 'bg-success';
    }
    setSelectedCells(selectedCells);
  };

  const isCellSelected = (day, time) => {
    return selectedCells[`${day}-${time}`] || false;
  };

  useEffect(() => {
    async function fetchData() {
      if (getLocalStorageWithExpiration('therapistAvailability')) {
        setLoading(true);
        const cookieData = getLocalStorageWithExpiration('therapistAvailability');
        setSelectedCells(cookieData);
        setLoading(false);
      } else {
        try {
          setLoading(true);

          const cookieData = Cookies.get("logindata");
          const token = cookieData ? JSON.parse(cookieData) : null;
          if (token === null){
              handle401Response();
          }
          const { access_token, sf_id } = token;

          const postData = {
            therapist_id: sf_id,
          };

          const response = await fetch(APIEndpoints.GET_THERAPIST, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${access_token}`,
            },
            body: JSON.stringify(postData),
          });

          if (response.status === 401) {
            // Handle 401 Unauthorized
            handle401Response();
            setLoading(false);
            return;
          }

          const data = await response.json();
          setSelectedCells(data.availability_data);
          // set data in local stor 
          setLocalStorageWithExpiration('therapistAvailability', data.availability_data, 30);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    }

    fetchData();
  }, []);

  const handleUpdateAvailability = async () => {
    setUpdating(true);
    try {
      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null){
          handle401Response();
      }
      const { access_token, sf_id } = token;

      const response = await fetch(APIEndpoints.UPDATE_THERAPIST, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`,
        },
        body: JSON.stringify({
          therapist_id: sf_id,
          availability_data: selectedCells,
        }),
      });

      if (response.status === 401) {
        // Handle 401 Unauthorized
        handle401Response();
        setUpdating(false);
        return;
      }

      if (response.ok) {
        // Remove from local storage for data updateion
        if (getLocalStorageWithExpiration('therapistAvailability')){
          removeLocalStorageItem('therapistAvailability', 0);
        }
        toast.success('Availability updated successfully', { position: toast.POSITION.TOP_RIGHT });
      } else {
        toast.error('Failed to update availability', { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      toast.error('Failed to update availability', { position: toast.POSITION.TOP_RIGHT });
    }
    setUpdating(false);
  };

  return (
    <>
      <Header />
      <ToastContainer style={{ top: '7rem', position: 'fixed' }} />
      {loading ? (<div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>                 <div className="spinner-border" role="status">                    <span className="sr-only">Loading...</span>                 </div>
      </div>) : (<>
        <div className="container-fluid custom-container">
          <div className="mt-4 justify-content-center">
            <div className="">
              <div>
                <div className="container mt-1">
                  <h4 className="text-center">
                    <u>Therapist Availability</u>
                  </h4>
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <Card className="w-100 mx-auto mt-3">
                        <Card.Body>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  {days.map((day, index) => (
                                    <th key={index} scope="col">
                                      {day}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {times.map((time, rowIndex) => (
                                  <tr key={rowIndex} style={{ cursor: 'pointer' }}>
                                    {days.map((day, colIndex) => (
                                      <td
                                        key={colIndex}
                                        onClick={(event) => toggleCell(event.target, day, time)}
                                        className={isCellSelected(day, time) ? 'bg-success' : ''}
                                      >
                                        {time}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="text-center my-4">
                            <button className="btn btn-dark" onClick={handleUpdateAvailability}>
                              {updating ? (
                                <span>
                                  Updating...{' '}
                                  <div className="spinner-border spinner-border-sm" role="status"></div>
                                </span>
                              ) : (
                                'Update Availability'
                              )}
                            </button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>)}

      {lastUpdatedTime ? <LastUpdatedTime time={lastUpdatedTime} /> : <LastUpdatedTime time={"0 min ago"} />}
     
    </>
  );
};

export default TherapistAvailability;
