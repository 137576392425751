import React, { useEffect, useState } from "react";
import Header from "./Header";
import axios from "axios";
import { APIEndpoints } from "./APIEndpoints";
import {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
} from "./localStorageUtil";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function SessionMissingTA() {

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [clickedTasks, setClickedTasks] = useState();
  const [submitting, setSumitting] = useState(false);
  const [submittingTask, setSumittingTask] = useState(false);
  const [submittingTaskIndex, setSubmittingTaskIndex] = useState(null);
  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    if (getLocalStorageWithExpiration("task-data")) {
      setIsLoading(true);
      const cookieData = getLocalStorageWithExpiration("task-data");
      setData(cookieData);
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const cookieData = Cookies.get("logindata");
        const token = cookieData ? JSON.parse(cookieData) : null;
        if (token === null){
            handle401Response();
        }
        const { access_token, sf_id } = token;
        const response = await axios.get(`${APIEndpoints.ALL_TASK}${sf_id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          }
        );
        setData(response.data);
        // Set Data in Cookie for 30 minutes
        setLocalStorageWithExpiration("task-data", response.data, 30);
        setIsLoading(false);
      } catch (error) {
        if(error.response.status === 401){
          handle401Response();
        }
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handelSubmit = async () => {
    if (description === "" || description.length === 0) {
      toast.warning("Description required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      setSumitting(true);
      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null){
          handle401Response();
      }
      const { access_token, sf_id } = token;
      const response = await axios.post(
        APIEndpoints.CREATE_CASE,
        {
          case_type: "SessionMissingOnTA",
          therapist_id: sf_id,
          state_payment_id: clickedTasks,
          case_category: "billing issue",
          case_details: "Missing session on TA system",
          description: description,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`,
          },
        }
      );
      const data = response.data;
      setSumitting(false);
      handleClose();
      setDescription("");
      toast.success("Successfully submitted", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      if(error.response.status === 401){
        handle401Response();
      }
      handleClose();
      setDescription("");
      setSumitting(false);
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error fetching data:", error);
    }
  };

  const createTask = async (payment_id) => {
    try {
      setSumittingTask(true);
      setSubmittingTaskIndex(payment_id);
      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null){
          handle401Response();
      }
      const { access_token } = token;
      const response = await axios.get(
        `${APIEndpoints.MISSING_TA_TASK}${payment_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`,
          },
        }
      );
      setSubmittingTaskIndex(null);
      setSumittingTask(false);

      if (response.status === 200) {
        toast.success(response?.data?.message || "Task created successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response?.data?.message || "Failed to create task", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setSumittingTask(false);
      setSubmittingTaskIndex(null);
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error fetching data:", error);
      if(error.response.status === 401){
        handle401Response();
      }
    }
  };

  const [selectedChild, setSelectedChild] = useState("");

  const uniqueChildNames = data.missing_sf_sessions
    ? [
      ...new Set(
        Object.values(data.missing_sf_sessions).map(
          (item) => item.child_name
        )
      ),
    ]
    : [];

  const handleSelectChange = (event) => {
    setSelectedChild(event.target.value);
  };

  const filteredData = selectedChild
    ? Object.values(data.missing_sf_sessions).filter((item) =>
      item.child_name.startsWith(selectedChild)
    )
    : [];

  return (
    <>
      <ToastContainer />
      <Header />
      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container custom-container my-5">
          <div className="my-2">
            <p className="mb-0">
              <strong>Sessions Not In TA System</strong>
            </p>
            <p>
              Sessions you've logged in EIMS that have not been logged in the
              Therapy Associates app.
            </p>
          </div>
          <div>
            <div className="my-3">
              <p>
                <strong>
                  <i className="fa fa-filter" aria-hidden="true"></i> Filter
                  Child
                </strong>
              </p>
              <select
                className="form-select"
                aria-label="Select Child Name"
                onChange={handleSelectChange}
                value={selectedChild}
              >
                <option value="">All children</option>
                {uniqueChildNames.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            {filteredData.length > 0 ? (
              filteredData.map((value, index) => (
                <div key={index} className="box p-3 mb-3 rounded">
                  <p className="ms-2">
                    <strong>{value?.child_name}</strong> <br />
                    {value?.service_type} <br />
                    {value?.service_date}
                  </p>
                </div>
              ))
            ) : (
              <div>
                {data.missing_sf_sessions &&
                Object.values(data.missing_sf_sessions).length > 0 ? (
                  Object.values(data.missing_sf_sessions).map(
                    (value, index) => {
                      return (
                        <div
                          key={index}
                          className="box d-flex flex-wrap gap-1 justify-content-between p-3 mb-3 rounded"
                        >
                          <div>
                            <p className="ms-2">
                              <strong>{value?.child_name}</strong> <br />
                              {value?.service_type} <br />
                              {value?.service_date}
                            </p>
                          </div>
                          {/* <div className="my-auto d-flex gap-1">
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={() => {
                        handleShow();
                        setDescription("");
                        setClickedTasks(value?.id);
                      }}
                    >
                      Need Assistance
                    </button>
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={() => {
                        createTask(value?.id);
                      }}
                    >
                      {submittingTaskIndex === value?.id ? (
                        <div>
                          Deleted From EIMS
                          <div
                            className="ms-2 spinner-border text-light"
                            style={{ width: "1rem", height: "1rem" }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        "Deleted From EIMS"
                      )}
                    </button>
                  </div> */}
                        </div>
                      );
                    }
                  )
                ) : (
                  <p className="text-center">There is nothing to process</p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="form-floating">
            <textarea
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
              style={{ height: "100px" }}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            <label for="floatingTextarea2">
              Please describe your issue here
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDescription("");
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handelSubmit}
            disabled={submitting}
          >
            {submitting ? "Submitting..." : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SessionMissingTA;
