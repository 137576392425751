import React, { useState } from "react";
import Header from "./Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIEndpoints } from "./APIEndpoints";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function PaymentAdminAuth() {
  const [formPassword, setFormPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    if (formPassword === "") {
      toast.error("Enter your password", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setIsLoading(true);
    const cookieData = Cookies.get("logindata");
    const token = cookieData ? JSON.parse(cookieData) : null;
    if (token === null){
        handle401Response();
    }
    const { access_token } = token;
    const url = APIEndpoints.AUTHENTICATE_APP_ADMIN;
    const method = "POST";
    const requestBody = JSON.stringify({ password: formPassword });
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${access_token}`
      },
      body: requestBody,
    });

    if (response.status === 401) {
      // Handle 401 Unauthorized
      handle401Response();
      return;
    }

    if (response.ok) {
      setIsLoading(false);
      const responseData = await response.json();
      const status = responseData.status;
      if (status) {
        navigate("/payment-script-run", {
          state: { verified: true },
        });
      } else {
        toast.error("Not Authenticated.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setFormPassword("");
      }
    } else {
      setIsLoading(false);
      toast.error("Not Authenticated", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormPassword("");
    }
  };
  return (
    <>
      <Header />
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />

      <div className="container custom-container my-5">
        <div className="row justify-content-center my-4">
          <div className="col-md-6">
            <div className="box p-4">
              <h3 className="mb-4 text-center">Authenticate Admin</h3>
              <div className="mb-3">
                <label htmlFor="form3Example4" className="form-label">
                  Enter Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="form3Example4"
                  placeholder="Enter password"
                  required
                  value={formPassword}
                  onChange={(e) => {
                    setFormPassword(e.target.value);
                  }}
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-dark text-white"
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <span>
                      Submit..{" "}
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    </span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentAdminAuth;
