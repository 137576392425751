import React, { useEffect, useState } from 'react';
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIEndpoints } from './APIEndpoints';
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function UploadFile() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null); // Add state for the selected file
    const [fileKey, setFileKey] = useState(0); 

    const verified = location?.state?.verified;

    useEffect(() => {
        // You don't need a separate function for this check
        if (!verified) {
            navigate('/file/password/');
        }
    }, [navigate, verified]);

    const handleFileChange = (event) => {
        // Update the state with the selected file
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!file) {
            toast.error("Please select a file to upload.", { position: toast.POSITION.TOP_RIGHT });
            return;
        }

        try {
            setIsLoading(true);
            const cookieData = Cookies.get("logindata");
            const token = cookieData ? JSON.parse(cookieData) : null;
            if (token === null){
                handle401Response();
            }
            const { access_token, sf_id } = token;

            const formData = new FormData();
            formData.append('csv', file);

            // Make the POST request to the API
            const response = await fetch(`${APIEndpoints.UPLOAD_CSV_FILE}${sf_id}`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
              body: formData,
            });

            if (response.status === 401) {
                // Handle 401 Unauthorized
                handle401Response();
                return;
              }

            // Handle the response from the API
            if (response.ok) {
                setIsLoading(false);
                toast.success("Successfully submitted.", { position: toast.POSITION.TOP_RIGHT });
                setTimeout(() => {
                    navigate('/');
                }, 5000);

            } else {
                setIsLoading(false);
                toast.error("Failed to Upload File", { position: toast.POSITION.TOP_RIGHT });
                console.error('Failed to Upload File');
                setFile(null);
                setFileKey(prevKey => prevKey + 1);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("An error occurred while uploading the file.", { position: toast.POSITION.TOP_RIGHT });
            console.error('Error:', error);
            setFile(null);
            setFileKey(prevKey => prevKey + 1);
        }
    };

    return (
        <>
            <Header />
            <ToastContainer style={{ top: '7rem', position: 'fixed' }} />
            <div className="container custom-container my-5">
                <div className="row my-4">
                    <form className="box py-4" onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
                        <div className="mb-3">
                            <label htmlFor="form3Example4" className="form-label">Upload your file</label>
                            <input
                                key={fileKey} 
                                type="file"
                                className="form-control"
                                id="form3Example4"
                                onChange={handleFileChange} // Add onChange event handler
                                required
                            />
                        </div>
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-info text-white"
                            >
                                {isLoading ? (<span>
                                    Submit.. {' '}
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                                </span>) : ("Submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default UploadFile;

