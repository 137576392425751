import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { APIEndpoints } from "./APIEndpoints";
import { Cookies } from "react-cookie";
import { removeLocalStorageItem } from "./localStorageUtil";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { json } from "../json.js";
import axios from "axios";

function DraftForm25() {
  const location = useLocation();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [discard, setDiscarding] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [name, setname] = useState(location?.state?.name);
  const [serviceId, setServiceId] = useState(location?.state?.service_id);
  const [therapistServiceId, setTherapistServiceId] = useState(
    location?.state?.therapist_service_id
  );
  const [pendingData, setPendingData] = useState(location?.state?.data?.item);

  const cookie = new Cookies();
  const token = cookie.get("logindata");

  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Change the threshold value as needed
      if (scrollPosition > 200) {
        setShowDiv(true);
      } else {
        setShowDiv(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [formData, setFormData] = useState({});
  useEffect(() => {
    setFormData({
      // Initialize with empty values for textareas and radios
      family_outcome: pendingData.family_outcome,
      child_outcome: pendingData.child_outcome,
      clinical_tool: pendingData.clinical_tool,
      challenges: pendingData.challenges,

      assessments: pendingData.assesments,
      recommendation: pendingData.recommendation,
      assesments_option: pendingData.assesments_option,
      recommendation_option: pendingData.recommendation_option,

      // Rows data

      outcome1: pendingData.outcome1,
      current1: pendingData.current1,
      initial1: pendingData.initial1,
      challenge1: false,
      outcome2: pendingData.outcome2,
      current2: pendingData.current2,
      initial2: pendingData.initial2,
      challenge2: false,
      outcome3: pendingData.outcome3,
      current3: pendingData.current3,
      initial3: pendingData.initial3,
      challenge3: false,
      outcome4: pendingData.outcome4,
      current4: pendingData.current4,
      initial4: pendingData.initial4,
      challenge4: false,
      outcome5: pendingData.outcome5,
      current5: pendingData.current5,
      initial5: pendingData.initial5,
      challenge5: false,
      outcome6: pendingData.outcome6,
      current6: pendingData.current6,
      initial6: pendingData.initial6,
      challenge6: false,
      outcome7: pendingData.outcome7,
      current7: pendingData.current7,
      initial7: pendingData.initial7,
      challenge7: false,
      outcome8: pendingData.outcome8,
      current8: pendingData.current8,
      initial8: pendingData.initial8,
      challenge8: false,
    });
  }, [pendingData]);

  const handleTextareaChange = (event, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: event.target.value,
    });
    setFormChanged(true);
  };

  const handleRadioChange = (event, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: event.target.value === "Yes", // Convert to boolean
    });
    setFormChanged(true);
  };

  // LOGIC FOR SIGNATURE PAD

  const [signatureData, setSignatureData] = useState();

  const survey = new Model(json);

  const handleClickOutside = (e) => {
    e.preventDefault();
    setFormChanged(true);
    // Clicked outside of the div element
    if (!signatureData) {
      const completeButton = document.querySelector(
        ".sd-btn--action.sd-navigation__complete-btn"
      );
      // Check if the element is found
      if (completeButton) {
        // Simulate a click on the element
        completeButton.click();
      } else {
        console.error("Element not found");
      }
    } else {
      const blob = dataURLtoBlob(signatureData);
      const file = new File([blob], "signature.png", { type: "image/png" });
      handleSubmit(file);
    }
  };

  survey.onComplete.add((sender, options) => {
    const data = sender.data.signature;
    setSignatureData(data);
    const blob = dataURLtoBlob(data);
    const file = new File([blob], "signature.png", { type: "image/png" });
    handleSubmit(file);
  });

  function dataURLtoBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  // End Signature logic
  const discard_saved_form = async (id) => {
    try {
      setDiscarding(true);
      const response = await axios.get(
        `${APIEndpoints.UPDATE_FORM_25_DRAFT}${id}/`
      );
      setDiscarding(false);
      toast.success("Successfully discarded.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setDiscarding(false);
    }
  };

  const savework = async () => {
    try {
      if (!formChanged) {
        toast.warning("Please fill form to save.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      setSaving(true);
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      formDataToSend.append("child_service_id", serviceId);

      // Make the POST request to the API
      const response = await fetch(
        `${APIEndpoints.UPDATE_FORM_25_DRAFT}${pendingData.id}/`,
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      // Handle the response from the API
      const data = await response.json();

      setLoading(false);

      toast.success("Successfully submitted.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      setSaving(false);
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleSubmit = async (signFile) => {
    if (!formChanged) {
      toast.warning("Please fill form to save.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      setLoading(true);

      if (!signFile) {
        toast.error("Please upload the signature before submitting.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      // Create a new FormData object
      const formDataToSend = new FormData();

      // Add the form fields to the formDataToSend object

      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      // Add the file to the formDataToSend object
      formDataToSend.append("file", signFile);
      formDataToSend.append("name", name);
      formDataToSend.append("child_service_id", serviceId);
      formDataToSend.append("therapist_id", token.sf_id);
      formDataToSend.append("therapist_service_id", therapistServiceId);

      // True if pending form data filling
      formDataToSend.append("is_from_pending", "True");
      formDataToSend.append("pending_form_id", pendingData.id);

      // Make the POST request to the API
      const response = await fetch(APIEndpoints.GENERATE_FORM_25_PDF, {
        method: "POST",
        body: formDataToSend,
      });

      // Handle the response from the API
      const data = await response.json();

      setLoading(false);

      toast.success("Successfully submitted.", {
        position: toast.POSITION.TOP_RIGHT,
      });

      // Delete some things from local storage
      removeLocalStorageItem("form25Task", 5);
      removeLocalStorageItem("form25Details", 5);

      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.error("Error in Upload form.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handelAssementChange = (event) => {
    const value = event.target.value;
    const element = document.getElementById("assesment-text");
    if (element) {
      if (value === "yes") {
        element.style.display = "block";
        element.style.setProperty("display", "block", "important");
      }
      if (value === "no") {
        element.style.display = "none";
        element.style.setProperty("display", "none", "important");
      }
    }
    setFormData({
      ...formData,
      ["assesments_option"]: event.target.value,
    });
  };

  const handelRecommendationChange = (event) => {
    const value = event.target.value;
    const element = document.getElementById("recommendation-text");
    if (element) {
      if (value === "no") {
        element.style.display = "none";
        element.style.setProperty("display", "none", "important");
      } else {
        element.style.display = "block";
        element.style.setProperty("display", "block", "important");
      }
    }
    setFormData({
      ...formData,
      ["recommendation_option"]: event.target.value,
    });
  };

  const handelDeleteSign = () => {
    setSignatureData();
  };

  return (
    <>
      <Header />
      <ToastContainer style={{ top: "7rem", position: "fixed" }} />
      <div className="container-fluid custom-container my-5">
        <div className="container-fluid my-5 h-100">
          <form onSubmit={(e) => handleClickOutside(e)}>
            <div
              className="container card"
              style={{ background: "transparent" }}
            >
              <div className="row p-2 text-center">
                <h5>{name}</h5>
              </div>
              <hr className="m-0" />
              <div className="row">
                <p className="my-2">
                  <strong>
                    Informal Recommendations (Information of service
                    coordinator- Will not appear on Form 25)
                  </strong>
                </p>
              </div>
              <hr className="m-0" />
              <div className="row">
                <p className="my-2">
                  <strong>
                    Are you Recommending additional assesments or service ?
                  </strong>
                </p>
                <div className="ms-2">
                  <select
                    className="form-select my-2 "
                    aria-label="Default select example"
                    onChange={handelAssementChange}
                    value={formData?.assesments_option?.toLowerCase()}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
              {formData?.assesments_option?.toLowerCase() === "yes" ? (
                <div className="row p-2 my-2" id="assesment-text">
                  <strong>Please explain</strong>
                  <textarea
                    name="assessments"
                    value={formData?.assessments}
                    onChange={(e) => handleTextareaChange(e, "assessments")}
                    rows={5}
                    cols={5}
                  ></textarea>
                </div>
              ) : (
                <></>
              )}
              <hr className="m-0" />
              <div className="row">
                <p className="my-2">
                  <strong>
                    Are you Recommending an increase, decrease or discharge to
                    service in your disciplane ?
                  </strong>
                </p>
              </div>
              <div className="row p-2">
                <select
                  className="form-select my-2 "
                  aria-label="Default select example"
                  onChange={handelRecommendationChange}
                  defaultValue=""
                >
                  <option value="no" selected>
                    No
                  </option>
                  <option value="increase">Increase</option>
                  <option value="decrease">Decrease</option>
                  <option value="discharge">Discharge</option>
                </select>
              </div>
              <div
                className="row p-2 my-2"
                style={{ display: "none" }}
                id="recommendation-text"
              >
                <strong>Please explain</strong>
                <textarea
                  name="recommendation"
                  value={formData?.recommendation}
                  onChange={(e) => handleTextareaChange(e, "recommendation")}
                  rows={5}
                  cols={5}
                ></textarea>
              </div>
              <hr className="m-0" />
              <div className="row">
                <h5 className="my-3">Form 25 Entry</h5>
              </div>
              <hr className="m-0" />
              <div className="row">
                <p className="my-3">
                  <strong>IFSP Review Summary</strong>
                </p>
                <p className="my-4 mb-0">
                  <strong>Family Outcomes:</strong> How have you helped the
                  family?
                </p>
                <p className="mb-0">1. Know their rights</p>
                <p className="mb-0">
                  2. Effectively communicate their child's needs
                </p>
                <p className="mb-0">3. Help their child develop and learn</p>
                <p className="my-2 mb-0">
                  <strong>Describe</strong>
                </p>
              </div>
              <div className="row p-2">
                <textarea
                  name="family_outcome"
                  value={formData?.family_outcome}
                  onChange={(e) => handleTextareaChange(e, "family_outcome")}
                  rows={5}
                  cols={5}
                ></textarea>
              </div>
              <div className="row">
                <p className="my-4 mb-0">
                  <strong>Child Outcomes:</strong> How have you helped the
                  child?
                </p>
                <p className="mb-0">
                  1. Develop positive social-emotional skills (including social
                  relationships)
                </p>
                <p className="mb-0">
                  2. Acquire and use of knowledge and skills (including early
                  language/ communication)
                </p>
                <p className="mb-0">
                  3. Use of appropriate behaviour to meet their needs
                </p>
                <p className="my-2 mb-0">
                  <strong>Describe</strong>
                </p>
              </div>
              <div className="row p-2">
                <textarea
                  name="child_outcome"
                  value={formData?.child_outcome}
                  onChange={(e) => handleTextareaChange(e, "child_outcome")}
                  rows={5}
                  cols={5}
                ></textarea>
              </div>
              <div className="row my-2">
                <p>
                  The following information is provided for consideration at the
                  upcoming IFSP review based upon my work with the child, the
                  child's family add other caregivers, since the last IFSP.
                </p>
              </div>
              <div className="row my-2">
                <p>
                  The following tools/early learning standards including ongoing
                  assesments and currculum (activities) were used with the child
                  and family during the early intervention service(s)
                  authorization period.
                </p>
              </div>
              <div className="row my-2">
                <p className="text-danger">
                  **Please include scores from an apropriate clinical tool
                </p>
              </div>
              <div className="row p-2">
                <textarea
                  name="clinical_tool"
                  value={formData?.clinical_tool}
                  onChange={(e) => handleTextareaChange(e, "clinical_tool")}
                  rows={5}
                  cols={5}
                ></textarea>
              </div>
              <div className="row p-2">
                <div className="table-responsive">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr>
                        <th scope="col" rowSpan={2}>
                          Outcome(List and program on <br /> all current IFSP
                          outcomes)
                        </th>
                        <th scope="col text-center" colSpan={2}>
                          Outcome Progress/ Status
                        </th>
                        <th scope="col" rowSpan={2}>
                          Challenges
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">Initial/Baseline status</th>
                        <th scope="col">Current status/Progress</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">
                          <textarea
                            name="outcome1"
                            value={formData?.outcome1}
                            onChange={(e) =>
                              handleTextareaChange(e, "outcome1")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="initial1"
                            value={formData?.initial1}
                            onChange={(e) =>
                              handleTextareaChange(e, "initial1")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="current1"
                            value={formData?.current1}
                            onChange={(e) =>
                              handleTextareaChange(e, "current1")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge1"
                              id="challenge11"
                              value="Yes"
                              checked={formData?.challenge1 === true}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge1")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge11"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge1"
                              id="challenge12"
                              value="No"
                              checked={formData?.challenge1 === false}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge1")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge12"
                            >
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">
                          <textarea
                            name="outcome2"
                            value={formData?.outcome2}
                            onChange={(e) =>
                              handleTextareaChange(e, "outcome2")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="initial2"
                            value={formData?.initial2}
                            onChange={(e) =>
                              handleTextareaChange(e, "initial2")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="current2"
                            value={formData?.current2}
                            onChange={(e) =>
                              handleTextareaChange(e, "current2")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge2"
                              id="challenge21"
                              value="Yes"
                              checked={formData?.challenge2 === true}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge2")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge21"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge2"
                              id="challenge22"
                              value="No"
                              checked={formData?.challenge2 === false}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge2")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge22"
                            >
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">
                          <textarea
                            name="outcome3"
                            value={formData?.outcome3}
                            onChange={(e) =>
                              handleTextareaChange(e, "outcome3")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="initial3"
                            value={formData?.initial3}
                            onChange={(e) =>
                              handleTextareaChange(e, "initial3")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="current3"
                            value={formData?.current3}
                            onChange={(e) =>
                              handleTextareaChange(e, "current3")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge3"
                              id="challenge31"
                              value="Yes"
                              checked={formData?.challenge3 === true}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge3")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge31"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge3"
                              id="challenge32"
                              value="No"
                              checked={formData?.challenge3 === false}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge3")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge32"
                            >
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">
                          <textarea
                            name="outcome4"
                            value={formData?.outcome4}
                            onChange={(e) =>
                              handleTextareaChange(e, "outcome4")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="initial4"
                            value={formData?.initial4}
                            onChange={(e) =>
                              handleTextareaChange(e, "initial4")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="current4"
                            value={formData?.current4}
                            onChange={(e) =>
                              handleTextareaChange(e, "current4")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge4"
                              id="challenge41"
                              value="Yes"
                              checked={formData?.challenge4 === true}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge4")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge41"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge4"
                              id="challenge42"
                              value="No"
                              checked={formData?.challenge4 === false}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge4")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge42"
                            >
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">
                          <textarea
                            name="outcome5"
                            value={formData?.outcome5}
                            onChange={(e) =>
                              handleTextareaChange(e, "outcome5")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="initial5"
                            value={formData?.initial5}
                            onChange={(e) =>
                              handleTextareaChange(e, "initial5")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="current5"
                            value={formData?.current5}
                            onChange={(e) =>
                              handleTextareaChange(e, "current5")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge5"
                              id="challenge51"
                              value="Yes"
                              checked={formData?.challenge5 === true}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge5")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge51"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge5"
                              id="challenge52"
                              value="No"
                              checked={formData?.challenge5 === false}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge5")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge52"
                            >
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">
                          <textarea
                            name="outcome6"
                            value={formData?.outcome6}
                            onChange={(e) =>
                              handleTextareaChange(e, "outcome6")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="initial6"
                            value={formData?.initial6}
                            onChange={(e) =>
                              handleTextareaChange(e, "initial6")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="current6"
                            value={formData?.current6}
                            onChange={(e) =>
                              handleTextareaChange(e, "current6")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge6"
                              id="challenge61"
                              value="Yes"
                              checked={formData?.challenge6 === true}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge6")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge61"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge6"
                              id="challenge62"
                              value="No"
                              checked={formData?.challenge6 === false}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge6")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge62"
                            >
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">
                          <textarea
                            name="outcome7"
                            value={formData?.outcome7}
                            onChange={(e) =>
                              handleTextareaChange(e, "outcome7")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="initial7"
                            value={formData?.initial7}
                            onChange={(e) =>
                              handleTextareaChange(e, "initial7")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="current7"
                            value={formData?.current7}
                            onChange={(e) =>
                              handleTextareaChange(e, "current7")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge7"
                              id="challenge71"
                              value="Yes"
                              checked={formData?.challenge7 === true}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge7")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge71"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge7"
                              id="challenge72"
                              value="No"
                              checked={formData?.challenge7 === false}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge7")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge72"
                            >
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">
                          <textarea
                            name="outcome8"
                            value={formData?.outcome8}
                            onChange={(e) =>
                              handleTextareaChange(e, "outcome8")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="initial8"
                            value={formData?.initial8}
                            onChange={(e) =>
                              handleTextareaChange(e, "initial8")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            name="current8"
                            value={formData?.current8}
                            onChange={(e) =>
                              handleTextareaChange(e, "current8")
                            }
                          ></textarea>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge8"
                              id="challenge81"
                              value="Yes"
                              checked={formData?.challenge8 === true}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge8")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge81"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="challenge8"
                              id="challenge82"
                              value="No"
                              checked={formData?.challenge8 === false}
                              onChange={(e) =>
                                handleRadioChange(e, "challenge8")
                              }
                            />
                            <label
                              className="form-check-label"
                              for="challenge82"
                            >
                              No
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <p className="my-2">
                  <strong>
                    If identifying challenges, describe the challenge(s) and
                    strategies implemented to address the challenge(s) ?
                  </strong>
                </p>
              </div>
              <div className="row p-2">
                <textarea
                  name="challenges"
                  value={formData?.challenges}
                  onChange={(e) => handleTextareaChange(e, "challenges")}
                  rows={5}
                  cols={5}
                ></textarea>
              </div>
              <div className="row p-2">
                <p>
                  <strong>
                    Practitioner signature{" "}
                    <span className="text-danger">*</span> (clear)
                  </strong>
                </p>

                {showDiv && (
                  <div className="mb-3">
                    <Survey model={survey} />
                    {signatureData && (
                      <div className="preview-signature text-center position-relative">
                        <img
                          src={signatureData}
                          style={{ width: "30%", border: "1px solid #1cb395" }}
                          alt="signature Image"
                        />
                        <span
                          className="position-absolute top-0 start-60 translate-middle p-1 bg-danger text-white border border-light rounded-circle"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handelDeleteSign();
                          }}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="p-4 text-center">
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <span>
                      Submiting...{" "}
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    </span>
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  type="button"
                  className="ms-2 btn btn-success"
                  onClick={savework}
                >
                  {saving ? (
                    <span>
                      Saving...{" "}
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    </span>
                  ) : (
                    "Save my work"
                  )}
                </button>

                <button
                  type="button"
                  className="ms-2 btn btn-dark"
                  onClick={() => {
                    discard_saved_form(pendingData.id);
                  }}
                >
                  {discard ? (
                    <span>
                      Discarding...{" "}
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    </span>
                  ) : (
                    "Discard"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default DraftForm25;
