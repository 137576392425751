export class APIEndpoints {
  static getBaseUrl() {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    switch (environment) {
      case "production":
        return process.env.REACT_APP_PRODUCTION_API_URL;
      case "staging":
        return process.env.REACT_APP_STAGING_API_URL;
      case "local":
        return process.env.REACT_APP_LOCAL_API_URL;
      default:
        throw new Error(`Invalid environment: ${environment}`);
    }
  }

  static BASE_URL = APIEndpoints.getBaseUrl();

  static LOGIN = `${APIEndpoints.BASE_URL}/users/token/`;
  static GET_THERAPIST = `${APIEndpoints.BASE_URL}/api/get/therapist/availability`;
  static UPDATE_THERAPIST = `${APIEndpoints.BASE_URL}/api/update/therapist/availability`;
  static GET_SELECTED_REGIONS = `${APIEndpoints.BASE_URL}/api/get/selected/regions/`;
  static UPDATE_SELECTED_REGIONS = `${APIEndpoints.BASE_URL}/api/update/selected/regions/`;
  static DELETE_SELECTED_REGIONS = `${APIEndpoints.BASE_URL}/api/delete/selected/region/`;
  static GET_BILLABLE_DATA = `${APIEndpoints.BASE_URL}/api/billable-services/`;
  static SESSION_VERIFICATION = `${APIEndpoints.BASE_URL}/api/session-verification/`;
  static BILLABLE_FORM_SUBMIT = `${APIEndpoints.BASE_URL}/api/create-billing-form/`;
  static ACTIVE_SERVICES = `${APIEndpoints.BASE_URL}/api/active-services/`;
  static SERVICE_DETAIL = `${APIEndpoints.BASE_URL}/api/service-details/`;
  static ADD_SIGNATURE = `${APIEndpoints.BASE_URL}/api/add-signature/`;
  static CREATE_SESSION = `${APIEndpoints.BASE_URL}/api/create-session/`;
  static CONTACT_FOR_SIGNATURE = `${APIEndpoints.BASE_URL}/api/contacts-for-signature/`;
  static SEND_SIGNATURE_REQUEST = `${APIEndpoints.BASE_URL}/api/send-signature-request/`;
  static AUTHENTICATE_APP_ADMIN = `${APIEndpoints.BASE_URL}/api/authenticate/app/admin`;
  static CHECK_SESSION_EXPIRED = `${APIEndpoints.BASE_URL}/api/sign/request/url/expired/`;
  static COMPLETE_SIGNATURE_REQUEST = `${APIEndpoints.BASE_URL}/api/complete/sign/request`;
  static UPLOAD_CSV_FILE = `${APIEndpoints.BASE_URL}/api/upload/billing/csv/`;
  static SESSION_INFO = `${APIEndpoints.BASE_URL}/api/session/info`;
  static GENERATE_FORM_25_PDF = `${APIEndpoints.BASE_URL}/api/form-25/pdf-generate`;
  static FORM_25_DETAILS = `${APIEndpoints.BASE_URL}/api/form-25-detail/`;
  static FORM_25_TASK = `${APIEndpoints.BASE_URL}/api/form25/task/`;
  static FORM_25_Task_UPDATE = `${APIEndpoints.BASE_URL}/api/form25/update/`;
  static UploadOnboardingDocument = `${APIEndpoints.BASE_URL}/api/upload/onboarding/doc/`;
  static THERAPIST_TRAINING_SUBMIT = `${APIEndpoints.BASE_URL}/api/submit/survey-answer/`;
  static SAVE_POLICY_SIGNATURE = `${APIEndpoints.BASE_URL}/api/save-policy-sign/`;
  static CHILD_ALL_CONTACTS = `${APIEndpoints.BASE_URL}/api/child-contacts/`;
  static SESSION_HISTORY = `${APIEndpoints.BASE_URL}/api/session-history/`;
  static DOWNLOAD_FORM25_PDF = `${APIEndpoints.BASE_URL}/api/form25/download/pdf/`;
  static SAVE_POLICY_PROCEDURE_DOCUMENT = `${APIEndpoints.BASE_URL}/api/save-policy-procedure/`;
  static PARENT_CHILD_CONTACTS = `${APIEndpoints.BASE_URL}/api/parent-child-contacts/`;
  static SAVE_FORM_25_DRAFT = `${APIEndpoints.BASE_URL}/api/form-25-save/`;
  static UPDATE_FORM_25_DRAFT = `${APIEndpoints.BASE_URL}/api/update/saved-form-25/`;
  static FETCH_SAVE_FORM_25_DRAFT = `${APIEndpoints.BASE_URL}/api/fetch-form25-save/`;
  static CREATE_SESSION_CANCELLATION = `${APIEndpoints.BASE_URL}/api/session-cancellation/`;
  static APP_DOCUMENT_REFERENCE = `${APIEndpoints.BASE_URL}/api/app-documents/reference/`;
  static GET_SESSION_DETAILS = `${APIEndpoints.BASE_URL}/api/session-details/`;
  static FORM_13_TASK = `${APIEndpoints.BASE_URL}/api/form13/`;
  static THERAPIST_AVAILABLE_TIME = `${APIEndpoints.BASE_URL}/api/therapist-available-time/`;
  static Messages = `${APIEndpoints.BASE_URL}/api/frontend-messages/`;
  static Alert = `${APIEndpoints.BASE_URL}/api/alert-messages/`;
  static DECLINE_AVAILABILITY = `${APIEndpoints.BASE_URL}/api/decline-availability/`;
  static PayStubb = `${APIEndpoints.BASE_URL}/api/sessions-payment-status/`;
  static UnpaidSessions = `${APIEndpoints.BASE_URL}/api/unpaid-sessions/`;
  static PaidSessions = `${APIEndpoints.BASE_URL}/api/paid-sessions/`;
  static DownloadPayStub = `${APIEndpoints.BASE_URL}/api/download-pay-stub/`;
  static DownloadYearlyPayStub = `${APIEndpoints.BASE_URL}/api/download-current-year-pay-stub/`;
  static RunPaymentFileScript = `${APIEndpoints.BASE_URL}/api/run-payment-file-script/`;

  // APEX
  static ALL_TASK = `${APIEndpoints.BASE_URL}/api/outstanding/tasks/`;
  static CREATE_CASE = `${APIEndpoints.BASE_URL}/api/session-issue/case/`;
  static CONSENT_FORM = `${APIEndpoints.BASE_URL}/api/contract-signature/`;
  static CONSENT_FORM_LINK = `${APIEndpoints.BASE_URL}/api/send-consentform-link/`;
  static MISSING_TA_TASK = `${APIEndpoints.BASE_URL}/api/missing-ta-task/`;

  // Rest Password
  static SEND_RESET_CODE = `${APIEndpoints.BASE_URL}/users/reset-password/code/`;
  static VERIFY_RESET_CODE = `${APIEndpoints.BASE_URL}/users/reset-password/otp/verification/`;
  static NEW_PASSWORD = `${APIEndpoints.BASE_URL}/users/reset-password/confirm/`;
  // Add more endpoints as needed
}
