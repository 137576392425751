import React, { useState, useEffect } from "react";
import Header from "./Header";
import { APIEndpoints } from "./APIEndpoints";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function Children() {
  const [childOptions, setChildOptions] = useState();
  const [isloading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const fetchChildOptions = async () => {
    try {
      setIsLoading(true);
      const cookieData = Cookies.get("logindata");
      const token = cookieData ? JSON.parse(cookieData) : null;
      if (token === null){
          handle401Response();
      }
      const { access_token, sf_id } = token;
      const response = await fetch(APIEndpoints.ACTIVE_SERVICES, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`,
        },
        body: JSON.stringify({
          sf_id: sf_id,
        }),
      });

      if (response.status === 401) {
        // Handle 401 Unauthorized
        handle401Response();
        return;
      }

      if (response.status === 200) {
        const data = await response.json();
        console.log(data,'ppp');
        setChildOptions(data);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChildOptions();
  }, []);
  return (
    <>
      <Header />
      {isloading ? (
        <div className="container-fluid custom-container my-5">
          <div className="container-fluid my-5 h-100">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container custom-container my-5">
            {childOptions ? (
              <>
                <div>
                  <p className="text-center mb-3 font-weight-bold px-2">
                    Select the child to log session and view past history
                  </p>
                </div>
                {Object.entries(childOptions)
                  .sort(([keyA, valueA], [keyB, valueB]) => {
                    return valueA.Name.localeCompare(valueB.Name);
                  })
                  .map(([key, value]) => (
                    <div
                      key={key}
                      className="box d-flex justify-content-between p-3 mb-3 rounded "
                      onClick={(e) => {
                        navigate("/child-detail", {
                          state: {
                            name: value?.Name,
                            id: value?.ServiceId,
                            childID: value?.Child_Id,
                            therapistServiceId: value?.TherapistServiceId,
                          },
                        });
                      }}
                    >
                      <div className="ms-2">
                        <p>
                          <span>{value?.Speciality}</span> <br />
                          <strong style={{ fontSize: "500" }}>
                            {value?.Name}
                          </strong>{" "}
                          <br />
                          {value?.Location}, {value?.Time} x {value?.Interval},{" "}
                          {value?.Minute} min
                        </p>
                      </div>
                      <div className="">
                        <i className="fa-solid fa-arrow-right"></i>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <>
                <p className="text-center">Data not found</p>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Children;
