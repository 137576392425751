import React, { useState } from 'react';
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { APIEndpoints } from './APIEndpoints';
import Cookies from 'js-cookie';
import axios from 'axios';
import { handle401Response } from "./authHelper";

function UploadOnboardingDoc() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null); // Add state for the selected file
    const [fileKey, setFileKey] = useState(0);

    const [docType, setDocType] = useState('');

    const handleTypeChange = (e) => {
        setDocType(e.target.value);
    };

    const handleFileChange = (event) => {
        // Update the state with the selected file
        setFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setIsLoading(true);

        if (docType === "") {
            toast.error("Please select a document type..", { position: toast.POSITION.TOP_RIGHT });
            setIsLoading(false);
            setFile(null);
            setFileKey(prevKey => prevKey + 1);
            return;
        }

        if (!file) {
            toast.error("Please select a file to upload.", { position: toast.POSITION.TOP_RIGHT });
            setIsLoading(false);
            return;
        }

        const cookieData = Cookies.get("logindata");
        const token = cookieData ? JSON.parse(cookieData) : null;
        if (token === null){
            handle401Response();
        }
        const { access_token, sf_id } = token;
        const url = APIEndpoints.UploadOnboardingDocument + `${sf_id}/${docType}`;
        const formData = new FormData();
        formData.append('file', file);

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${access_token}`,
            },
        }).then((res) => {
            setIsLoading(false);
            setFile(null);
            setFileKey(prevKey => prevKey + 1);
            setDocType("");
            toast.success(res?.data?.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
                navigate('/');
            }, 2000);

        })
            .catch((err) => {
                if(err.response.status === 401){
                    handle401Response();
                }
                setIsLoading(false);
                setFile(null);
                setFileKey(prevKey => prevKey + 1);
                setDocType("");
                toast.error("Failed to upload", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });

    };

    return (
        <>
            <Header />
            <ToastContainer style={{ top: '7rem', position: 'fixed' }} />
            <div className="container custom-container my-5">
                <div className="row my-4">
                    <form className="box py-4" onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
                        <div className="mb-3 text-center">
                            <h5>Upload Here</h5>
                        </div>
                        <div className="mb-3">
                            <select name="" id="" className='form-control' value={docType} onChange={handleTypeChange}>
                                <option defaultValue="">Select Document Type</option>
                                <option value="Welcome to EI Cert">Welcome to EI Cert</option>
                                <option value="Intro to IFSP Dev Cert">Intro to IFSP Dev Cert</option>
                                <option value="Procedural Safeguards Cert">Procedural Safeguards Cert</option>
                                <option value="Liability Insurance">Liability Insurance</option>
                                <option value="Teacher of Students with Disabilities certification">Teacher of Students with Disabilities certification</option>
                                <option value="License">License</option>
                                <option value="Transcripts">Transcripts</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <input
                                key={fileKey}
                                type="file"
                                className="form-control"
                                id="form3Example4"
                                onChange={handleFileChange} // Add onChange event handler
                                required
                            />
                        </div>
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-info text-white"
                            >
                                {isLoading ? (<span>
                                    Submit.. {' '}
                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                                </span>) : ("Submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default UploadOnboardingDoc;
