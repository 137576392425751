import Cookies from "js-cookie";

export const handle401Response = () => {
  Cookies.remove("logindata");
  clearAllLocalStorage(); // Ensure this function is globally accessible or imported
  window.location.href = "/login";
};

// Utility to clear localStorage
export const clearAllLocalStorage = () => {
  localStorage.clear();
};
