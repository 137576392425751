import React, { useEffect, useState } from 'react';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { APIEndpoints } from './APIEndpoints';
import { setLocalStorageWithExpiration, getLocalStorageWithExpiration } from './localStorageUtil';
import Cookies from 'js-cookie';
import { handle401Response } from "./authHelper";

function ParentContactAttemptTask() {
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [clickedTasks, setClickedTasks] = useState([]);

//   const handleBoxClick = (serviceName, yearMonth) => {
//     const cookies = new Cookies();
//     const expiration = new Date(new Date().getTime() + 5 * 60 * 1000); // 5 minutes from now
//     const clickedTasks = cookies.get('clickedTasks') || [];
  
//     const newClickedTask = { serviceName, yearMonth };
//     const updatedClickedTasks = [...clickedTasks, newClickedTask];
  
//     cookies.set('clickedTasks', updatedClickedTasks, { path: '/', expires: expiration });
//     setClickedTasks(updatedClickedTasks);
//   };


  const fetchData = async () => {
    if (getLocalStorageWithExpiration('task-data')) {
      setIsLoading(true);
      const cookieData = getLocalStorageWithExpiration('task-data');
      setData(cookieData);
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const cookieData = Cookies.get("logindata");
        const token = cookieData ? JSON.parse(cookieData) : null;
        if (token === null){
            handle401Response();
        }
        const { access_token, sf_id } = token;
        const response = await axios.get(
          `${APIEndpoints.ALL_TASK}${sf_id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            }
          }
        );
        setData(response.data);
        // Set Data in Cookie for 30 minutes
        setLocalStorageWithExpiration('task-data', response.data, 30);
        setIsLoading(false);
      } catch (error) {
        if(error.response.status === 401){
          handle401Response();
        }
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header />
      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container custom-container my-5">
          {data.contact_attempt_services && Object.values(data.contact_attempt_services).length > 0 ? (
            Object.values(data.contact_attempt_services).map((value, index) => {

              return (
                <div
                  key={index}
                  className="box d-flex justify-content-between p-3 mb-3 rounded"
                  onClick={(e) => {
                    navigate("/parent-contacts", {
                      state: {
                        service_id: value?.child_service_id,
                        childID: value?.child_id,
                        therapistServiceId: value?.therapist_service_id,
                      },
                    });
                  }}
                >
                  <div>
                    <p className="ms-2">
                      {value?.name}
                    </p>
                  </div>
                  <div className="my-auto">
                    <i
                      className="fa-solid fa-arrow-right"
                    ></i>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-center">There is nothing to process</p>
          )}
        </div>
      )}
    </>
  );
}

export default ParentContactAttemptTask;

